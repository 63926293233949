// ** packages **
import { PRIVATE_NAVIGATION } from "constants/navigation.constant";
import { useLoggedUserAPI } from "modules/Auth/services";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setAuthenticated, setCurrentUser } from "redux/slices/authSlice";

const useLoginService = () => {
  const { loggedUserAPI } = useLoggedUserAPI();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isVerified = async () => {
    const { data, error }: any = await loggedUserAPI({});
    if (!error && data) {
      const user = {
        firstName: data.first_name,
        lastName: data.last_name,
        phoneNumber: data.contact_number,
        email: data.email,
        profile: data.profile_image,
        permissions: data.permissions,
        is_super_administrator: data.is_super_administrator,
        current_plan: data.current_plan,
      };
      dispatch(setCurrentUser({ user }));
      dispatch(
        setAuthenticated({
          isAuthenticated: true,
        })
      );
    }
  };
  return { isVerified };
};
export default useLoginService;
