import SubscriptionPlan from "modules/Setting/sub-modules/PaymentSetting/components/SubscriptionPlan";

const AdminPlans = () => {
  return (
    <div>
      <SubscriptionPlan isSuperAdminPlan={true} />
    </div>
  );
};

export default AdminPlans;
