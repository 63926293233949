import { IconInputProps } from ".";

export const Dinners = ({ className }: IconInputProps) => {
  return (
    <svg
      className={className ?? ""}
      width="100px"
      height="70px"
      viewBox="0 -140 780 780"
      enableBackground="new 0 0 780 500"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40,0h700c22.092,0,40,17.909,40,40v420c0,22.092-17.908,40-40,40H40c-22.091,0-40-17.908-40-40V40   C0,17.909,17.909,0,40,0z"
        fill="#0079BE"
      />
      <path
        d="m599.93 251.45c0-99.415-82.98-168.13-173.9-168.1h-78.242c-92.003-0.033-167.73 68.705-167.73 168.1 0 90.93 75.727 165.64 167.73 165.2h78.242c90.914 0.436 173.9-74.294 173.9-165.2z"
        fill="#ffffff"
      />
      <path
        d="m348.28 97.43c-84.07 0.027-152.19 68.308-152.21 152.58 0.02 84.258 68.144 152.53 152.21 152.56 84.09-0.027 152.23-68.303 152.24-152.56-0.011-84.272-68.149-152.55-152.24-152.58z"
        fill="#0079BE"
      />
      <path
        d="m252.07 249.6c0.08-41.181 25.746-76.297 61.94-90.25v180.48c-36.194-13.948-61.861-49.045-61.94-90.23zm131 90.274v-180.53c36.207 13.92 61.914 49.057 61.979 90.257-0.065 41.212-25.772 76.322-61.979 90.269z"
        fill="#ffffff"
      />
    </svg>
  );
};
