import {
  differenceInDays,
  formatDistance,
  isToday,
  isTomorrow,
  formatRelative,
  format,
} from "date-fns";
export const parseData = (data: any) => {
  try {
    return JSON.parse(data);
  } catch (e) {
    return null;
  }
};

export const srcFilePath = (file: string | File, serverPath = false) => {
  if (typeof file === "string") {
    if (serverPath) {
      return `${file}`;
    }
  } else if (file) {
    return URL.createObjectURL(file);
  } else {
    //
  }
  return file;
};

export const convertTitleCase = (name: string) => {
  const splitName = name?.split("_");
  let titleCase = "";
  if (splitName?.length > 1) {
    const capitalizedParts = splitName?.map(
      (part) => part?.charAt(0)?.toUpperCase() + part?.substr(1)?.toLowerCase()
    );
    titleCase = capitalizedParts?.join(" ");
  } else {
    titleCase = name?.charAt(0)?.toUpperCase() + name?.substr(1)?.toLowerCase();
  }
  return titleCase;
};

export const firstCharUpperCase = (name: string) => {
  const upperCaseFirstChar = name?.charAt(0)?.toUpperCase() + name?.slice(1);
  return upperCaseFirstChar;
};
export const customRandomNumberGenerator = (max: number | null = null) => {
  const Num1 = 5244454888;
  const Num2 = 78987445454111155;
  let totalNum = Date.now();
  // eslint-disable-next-line no-bitwise
  totalNum = (Num2 * totalNum + Num1) & 0x7fffffff;
  if (max) {
    return (totalNum % max) + 1;
  }
  return totalNum + 1;
};

export const isTodayDate = (date: string) => {
  return isToday(new Date(date));
};

export const isTomorrowDate = (date: string) => {
  return isTomorrow(new Date(date));
};

// ** sec to convert hr min and sec time show
export const HoursMinSecFormat = (seconds: number) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  let formattedTime = "";

  if (hours > 0) {
    formattedTime += `${hours} hr `;
  }

  if (minutes > 0) {
    formattedTime += `${minutes} min `;
  }

  if (remainingSeconds > 0 || formattedTime === "") {
    formattedTime += `${remainingSeconds?.toFixed(0)} sec`;
  }

  return formattedTime?.trim();
};

export const timeToMilliseconds = (timeString: any) => {
  const [hours, minutes, seconds] = timeString?.split(":")?.map(Number);
  return (hours * 60 * 60 + minutes * 60 + seconds) * 1000;
};

export const convertUTCToLocal = (
  utcDateString: string,
  want_string = true,
  date_format:string = "dd MMM yyyy, hh:mm a"
) => {
  const parsedDate = Date.parse(utcDateString);
  const timezoneOffset = new Date().getTimezoneOffset() * 60000;
  const localDate = new Date(parsedDate - timezoneOffset);
  if (want_string) {
    const localDateStr: string = format(localDate, date_format);
    return localDateStr as string;
  } else {
    return localDate as Date;
  }
};

export const formatTimeAgo = (timestamp: string) => {
  const currentDate = new Date();
  const targetDate = new Date(timestamp);
  const currentDateInLocalTime = convertUTCToLocal(timestamp, false) as Date;

  const difference = differenceInDays(currentDate, targetDate);

  if (difference < 1) {
    return formatDistance(currentDateInLocalTime, new Date(), {
      addSuffix: true,
    });
  } else if (difference < 2) {
    return formatRelative(currentDateInLocalTime, new Date());
  } else {
    return convertUTCToLocal(timestamp) as string;
  }
};
