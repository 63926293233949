import { memo, useCallback, useRef } from "react";
import DataTableReact, { TableRefType } from "components/DataTable";
import useColRefInvoices from "../hooks/useColRefInvoices";
import { debounce } from "lodash";
import { Search } from "react-feather";
import { useGetTransactionHistory } from "../services";

const InvoiceList = () => {
  const tableRef = useRef<TableRefType>(null);
  const { getTransactionHistory, isLoading } = useGetTransactionHistory();
  const columnDefs = useColRefInvoices();

  const getTableData = async (params: {
    page: number;
    perPage: number;
    sortField: string;
    sortOrder: string;
    search: string;
  }) => {
    let tableData: any = { rowData: [], rowCount: 10 };
    const { data, error } = await getTransactionHistory({
      params: {
        page: params.page,
        per_page: params.perPage,
        sort_by: params.sortField,
        sort_order: params.sortOrder,
        search: params.search,
        types: "invoice",
      },
    });
    if (data && !error) {
      tableData = {
        rowData: data?.data || [],
        rowCount: data?.count || 0,
      };
    }
    return tableData;
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    tableRef.current?.onChange?.(e);
  };

  const dataTable = useCallback(() => {
    return (
      <DataTableReact
        setTableLength={null}
        key={"invoice"}
        ref={tableRef}
        columns={columnDefs}
        loader={isLoading}
        getData={(params: {
          page: number;
          perPage: number;
          sortField: string;
          sortOrder: string;
          search: string;
        }) => getTableData(params)}
      />
    );
  }, []);

  return (
    <div>
      <div className="flex md:flex-col items-center md:items-start justify-between mt-[15px] mb-[15px]">
        <div className="form__group">
          <div className="field__wrapper mb-0">
            <div className="field__inner__wrapper field__has__icon icon__position__left">
              <input
                className="input__DSD"
                type="text"
                placeholder="Search"
                onChange={debounce(onSearchChange, 300)}
              />
              <div className="icon__wrapper">
                <Search />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="reactDataTable__DSD billingtable">{dataTable()}</div>
    </div>
  );
};

export default memo(InvoiceList);
