// ** Packages **
import { Path } from "react-hook-form";

// ** CSS **
import "../style/checkbox.css";

// ** Types **
import { CheckboxPropsTypes } from "components/FormField/types/formField.types";
import Label from "./Label";

const CheckBoxField = <TFormValues extends Record<string, unknown>>(
  fieldProps: CheckboxPropsTypes<TFormValues>
) => {
  const {
    id,
    name,
    errors,
    register,
    options = [],
    className = "",
    labelClass = "",
    fieldLabelClass = "",
    hasFieldLabel = true,
    disabled = false,
    wrapperClass = "",
    fromGroupClass = "",
    value: defaultValue,
    label: defaultLabel,
    onChange = () => ({}),
    defaultChecked = false,
    type,
  } = fieldProps;

  const renderInput = ({
    value,
    selected,
    name: checkboxName,
  }: {
    value?: string | number | readonly string[];
    selected: boolean;
    name?: Path<TFormValues>;
  }) => {
    return (
      <input
        id={id}
        value={value}
        type={type || "checkbox"}
        autoComplete="off"
        disabled={disabled}
        onChange={onChange}
        name={checkboxName}
        className={className}
        {...(name && { name })}
        key={value?.toString()}
        defaultChecked={selected}
        {...(register && name && register(name, { onChange }))}
      />
    );
  };

  const checkBoxWrapper = (wrapperProps: {
    label: string | undefined;
    value: string | number | readonly string[] | undefined;
    selected: boolean | string;
  }) => {
    const { label, value, selected } = wrapperProps;
    return (
      <div
        className={`custom__checkbox__SD ${
          !label ? "without__label" : ""
        } ${wrapperClass} ${fromGroupClass} ${
          disabled ? "pointer-events-none opacity-50" : ""
        }`}
        key={label?.toString()}
      >
        {renderInput({
          name,
          value,
          selected: !!selected,
        })}
        {label ? (
          <Label id={id} label={label} labelClass={labelClass} />
        ) : (
          <label className="label__SD" />
        )}
      </div>
    );
  };

  return (
    <div className={`${hasFieldLabel ? "" : ""}`}>
      {defaultLabel && hasFieldLabel ? (
        <Label id={id} label={defaultLabel} labelClass={fieldLabelClass} />
      ) : (
        <label className="label__SD" />
      )}
      {!options.length
        ? checkBoxWrapper({
            label: defaultLabel,
            value: defaultValue,
            selected: defaultChecked,
          })
        : options.map((option) =>
            checkBoxWrapper({
              label: option.label,
              value: option?.value,
              selected: Boolean(option?.selected),
            })
          )}
      {errors?.message && <p className="error__message">{errors?.message}</p>}
    </div>
  );
};

export default CheckBoxField;
