// Basic Error Message
export enum BasicErrorMessage {
  numberOnly = "Please enter only number",
  first_name = "First name is required",
  last_name = "Last name is required",
  valid_number = "Please enter a valid number",
  minLengthName = "should be at least 3 characters",
  maxLengthName = "should be at most 75 characters",
  onlyAlphanumeric = "Must contain only alphabetic characters",
  name = "Name is required",
}

/* ---------------------- Password Error Message ---------------------- */
export enum PasswordError {
  required = "Password is required",
  valid = "Passwords must be at least 8 characters with a mix of uppercase, lowercase, numbers and symbols.",
  confirm_required = "Confirm password is required",
  minLengthReq = "At least 12 characters",
  upperLowerReq = "At least 1 uppercase and 1 lowercase",
  numberReq = "At least 1 number",
  specialCharReq = "At least 1 special character.",
  match = "Password and confirm password must match",
}

/* ------------------------Email Error Message ---------------------- */
export enum EmailError {
  required = "Email is required",
  valid = "Please enter a valid email address",
}

/* ---------------------------Phone Number Error Message ---------------------- */
export enum PhoneNumberError {
  required = "Phone number is required",
  valid = "Phone number is not valid",
}

/* ---------------------------File Error Message ---------------------- */
export enum FileError {
  required = "Please select file",
  size = "File size must be less than 15 MB",
  fileType = "Only PDF and image files are allowed",
}

/* ---------------------------Date Error Message ---------------------- */
export enum DateError {
  required = "Date is required",
  minLengthReq = "Date must be later than current date and time",
  typeError = "Invalid type of date",
}

/* ------------------------ Register Error Message ---------------------- */
export const RegisterSchemaError = {
  firstName: BasicErrorMessage.first_name,
  lastName: BasicErrorMessage.last_name,
  email: EmailError,
  Password: PasswordError,
  confirmPassword: PasswordError,
  phoneNumber: PhoneNumberError,
};

/*-----------------------------------------Add user model Error Message -----------------------------*/

export const AddUserModelSchemaError = {
  firstName: BasicErrorMessage,
  lastName: BasicErrorMessage,
  email: EmailError,
  phoneNumber: PhoneNumberError,
};

/*-----------------------------------------Add Patient model Error Message -----------------------------*/

export const addPatientModelSchemaError = {
  name: BasicErrorMessage,
  schedule_appointment: "Schedule appointment is required",
  email: EmailError,
  phoneNumber: PhoneNumberError,
  patient_file: FileError,
};

/* ----------------------------------------- create role and permission Error Message -----------------------------*/
export const CreateRoleAndPermissionSchemaError = {
  role: "Role is required",
  min: `Role name ${BasicErrorMessage.minLengthName}`,
};

/* ----------------------------------------- create role and permission Error Message -----------------------------*/
export const CreateCustomPromptSchemaError = {
  title: "Title is required",
  title_min: `Title ${BasicErrorMessage.minLengthName}`,
  title_max: `Title should be at most 50 characters`,
  description: "Description is required",
  description_min: `Description ${BasicErrorMessage.minLengthName}`,
  description_max: `Title should be at most 250 characters`,
  prompt: "Prompt is required",
  prompt_type: "Select Prompt Type",
};



/* ----------------------------------------- Superadmin Plan Error Message -----------------------------*/
export const SuperAdminPlanSchemaError = {
  plan_name: "Plan name is required",
  title_min: `Plan ${BasicErrorMessage.minLengthName}`,
  title_max: `Plan name should be at most 50 characters`,
  description: "Description is required",
  description_min: `Description ${BasicErrorMessage.minLengthName}`,
  description_max: `Description should be at most 250 characters`,
  onlyAlphanumeric: "Must contain only alphabetic characters",
  price_id: "Price ID is required",
  specifications_required: "At least one specification is required",
  specification_limit: "Specification limit must be 'true' or 'false'",
};