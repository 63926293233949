import PaymentFailureSvg from "components/Icon/SvgIcon/PaymentFailureSvg";

const PaymentFailure = () => {
  return (
    <div className="w-fit h-96 content-center mx-auto mt-[15%] text-center">
      <PaymentFailureSvg />
      <div>
        <p className="text-[24px] font-bold text-violet-700 m-4">
          Your Transaction has been declined, Please Try Again Later!
          &nbsp;&nbsp;&nbsp;: (
        </p>
      </div>
    </div>
  );
};

export default PaymentFailure;
