// ** Packages **
import { useEffect, useRef, useState } from "react";
import { Controller } from "react-hook-form";
import ReactSelect, { MultiValue, SingleValue } from "react-select";

// ** Components **
import Label from "./Label";

// ** Type **
import {
  Option,
  ReactSelectPropsTypes,
} from "components/FormField/types/formField.types";

const AsyncSelectField = <TFormValues extends Record<string, unknown>>(
  props: ReactSelectPropsTypes<TFormValues>
) => {
  const {
    name,
    label,
    value,
    errors,
    control,
    getOptions, // EX. ()=>{{label:"",value:""}}
    getOnChange,
    defaultOptions, // EX. {label:"",value:""}
    isMulti = false,
    OptionComponent,
    labelClass = "",
    required = false,
    noOptionsMessage,
    placeholder = "",
    disabled = false,
    isLoading = false,
    defaultSelectValue, // EX. {label:"",value:""}
    singleValueComponent,
    menuPlacement = "auto",
    onFocusApiCall = true,
    menuPosition = "fixed",
    onChange: onCustomChange,
    MultiValueComponent,
  } = props;
  const selectRef = useRef<any>(null);
  const [page, setPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const [search, setSearch] = useState<string>("");
  const [options, setOptions] = useState<Option[]>(
    defaultOptions ?? [{ label: "", value: "" }]
  );
  useEffect(() => {
    if (selectRef?.current?.inputRef) {
      selectRef.current.inputRef.autocomplete = "new-password";
    }
  }, [selectRef?.current?.inputRef]);

  const fetchOption = async ({ pageNo = page, optionValue = search } = {}) => {
    const data = await getOptions?.({
      search: optionValue,
      page: pageNo,
      name,
    });
    if (data) {
      setTotal(data.count || 0);
      if (pageNo !== 1) {
        const tempOptions = [...options];
        data.option?.forEach((op) => {
          if (!tempOptions.find((el) => el.value === op.value)) {
            tempOptions.push(op);
          }
        });
        setOptions(tempOptions);
      } else {
        setOptions(data.option);
      }
      setPage(pageNo + 1);
    } else {
      setPage(1);
      setSearch("");
      setTotal(0);
    }
    return data;
  };

  const onMenuScrollToBottom = (e: any) => {
    if (
      e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight &&
      total > options.length &&
      getOptions
    ) {
      fetchOption();
    }
  };
  return (
    <div className="form__group">
      <div className="select__SD field__wrapper mb-0">
        {label && (
          <Label label={label} required={required} labelClass={labelClass} />
        )}
        {control && name ? (
          <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value: innerValue } }) => {
              const controllerValue = innerValue as
                | MultiValue<Option>
                | SingleValue<Option>;
              return (
                <ReactSelect
                  isClearable
                  key={name}
                  ref={selectRef}
                  options={options}
                  isDisabled={disabled}
                  isLoading={isLoading}
                  className="basic-single"
                  placeholder={placeholder}
                  menuPosition={menuPosition}
                  classNamePrefix="select__SD"
                  {...(isMulti && { isMulti })}
                  menuPlacement={menuPlacement}
                  onMenuScrollToBottom={onMenuScrollToBottom}
                  {...(noOptionsMessage && { noOptionsMessage })}
                  {...(isMulti && { defaultValue: defaultOptions })}
                  value={controllerValue}
                  defaultValue={defaultSelectValue}
                  {...(onFocusApiCall && {
                    onFocus: () => getOptions && fetchOption({ pageNo: 1 }),
                  })}
                  onChange={(selectedOption) => {
                    onChange(selectedOption);
                    getOnChange?.(selectedOption);
                    onCustomChange?.(selectedOption);
                  }}
                  components={{
                    ...(OptionComponent && { Option: OptionComponent }),
                    ...(singleValueComponent && {
                      SingleValue: singleValueComponent,
                    }),
                    ...(MultiValueComponent && {
                      MultiValueLabel: MultiValueComponent,
                    }),
                  }}
                />
              );
            }}
          />
        ) : (
          <ReactSelect
            isClearable
            value={value}
            ref={selectRef}
            options={options}
            isLoading={isLoading}
            isDisabled={disabled}
            className="basic-single"
            placeholder={placeholder}
            menuPosition={menuPosition}
            classNamePrefix="select__SD"
            {...(isMulti && { isMulti })}
            menuPlacement={menuPlacement}
            defaultValue={
              defaultSelectValue?.value
                ? defaultSelectValue
                : {
                    label: "",
                    value: "",
                  }
            }
            onMenuScrollToBottom={onMenuScrollToBottom}
            {...(noOptionsMessage && { noOptionsMessage })}
            {...(isMulti && { defaultValue: defaultOptions })}
            {...(onFocusApiCall && {
              onFocus: () => fetchOption({ pageNo: 1 }),
            })}
            onChange={(selectedOption) => {
              getOnChange?.(selectedOption);
              onCustomChange?.(selectedOption);
            }}
            components={{
              ...(OptionComponent && { Option: OptionComponent }),
              ...(singleValueComponent && {
                SingleValue: singleValueComponent,
              }),
            }}
          />
        )}
      </div>
      {errors?.message && <p className="error__message">{errors?.message}</p>}{" "}
    </div>
  );
};

export default AsyncSelectField;
