import { BlockquoteIcon } from "./blockquoteIcon";
import { BoldIcon } from "./BoldIcon";
import { BulletLineIcon } from "./BulletLineIcon";
import { CenteralignIcon } from "./centeralignIcon";
import { CodeBlockIcon } from "./CodeBlockIcon";
import { CompleteIcon } from "./CompleteIcon";
import { ContentNotesIcon } from "./ContentNotesIcon";
import { CustomerSolidIcon } from "./CustomerSolidIcon";
import { DashboardIcon } from "./DashbordIcon";
import { DeleteIcon, DeleteIconDark } from "./DeleteIcon";
import { DocxIcon } from "./DocxIcon";
import { DoubleManIcon } from "./DoubleManIcon";
import { DownloadIcon } from "./DownloadCloudIcon";
import { FailedIcon } from "./FailedIcon";
import { FilledOneManIcon } from "./FilledOneManIcon";
import { H1Icon } from "./h1Icon";
import { H2Icon } from "./h2Icon";
import { H3Icon } from "./h3Icon";
import { H4Icon } from "./h4Icon";
import { HorizontalRuleIcon } from "./horizontalRuleIcon";
import { InProgressIcon } from "./InProgressIcon";
import { ItalicIcon } from "./ItalicIcon";
import { JustifyalignIcon } from "./JustifyalignIcon";
import { LeftalignIcon } from "./leftalignIcon";
import { LockIcon } from "./LockIcon";
import { MicrophoneIcon } from "./MIcrophoneIcon";
import { MicrophoneOffIcon } from "./MicrophoneOffIcon";
import { OrderListIcon } from "./OrderListIcon";
import { ParagraphIcon } from "./paragraphIcon";
import { PatientNotesIcon } from "./PatientNotesIcon";
import { PatientPlusIcon } from "./PatientPlusIcon";
import { PauseCircleIcon } from "./PauseCircleIcon";
import { PauseIcon } from "./PauseIcon";
import { PdfIcon } from "./PdfIcon";
import { PendingIcon } from "./PendingIcon";
import { PlayIcon } from "./PlayIcon";
import { PlayIcon2 } from "./PlayIcon2";
import { PromptsIcon } from "./PromptsIcon";
import { RedoIcon } from "./RedoIcon";
import { RestartIcon } from "./RestartIcon";
import { RightalignIcon } from "./rightalignIcon";
import { SettingIcon } from "./SettingIcon";
import { SingleManIcon } from "./SingleManIcon";
import { SkipBackIcon } from "./SkipBackIcon";
import { SkipForwardIcon } from "./SkipForwardIcon";
import { SolidDoubleIcon } from "./SolidDoubleIcon";
import { SolidLockIcon } from "./SolidLockIcon";
import { SolidSettingIcon } from "./SolidSettingIcon";
import { SrtFileIcon } from "./SrtFileIcon";
import { StopIcon } from "./StopIcon";
import { StopIcon2 } from "./StopIcon2";
import { Clock, DollarSign, Folder, Slack, Trello } from "react-feather";
import { TextFileIcon } from "./TextFileIcon";
import { TrashIcon } from "./TrashIcon";
import { TrashSolidIcon } from "./TrashIconSolid";
import { UnderlineIcon } from "./UnderlineIcon";
import { UndoIcon } from "./UndoIcon";
import { UndoIcon2 } from "./UndoIcon2";
import { VoiceNotesIcon } from "./VoiceNotesIcon";
import { ContentNotesDarkIcon } from "./ContentNotesDarkIcon";
import { VoiceNotesDarkIcon } from "./VoiceNotesDarkIcon";
import { AINotesIcon } from "./AINotesIcon";
import { AmplifierVoiceIcon } from "./AmplifierVoiceIcon";
import { MeetingIcon } from "./MeetingIcon";
import { ContentListIcon } from "./ContentListIcon";
import { Visa } from "./Visa";
import AmericanExpress from "./AmericanExpress";
import JCB from "./JCB";
import Discover from "./Discover";
import MasterCard from "./MasterCard";
import UnionPay from "./UnionPay";
import { Dinners } from "./Dinners";
import { CreditCard } from "./CreditCard";
import { SolidDollarSign } from "./DollarSign";
import { SolidTrelloIcon } from "./SolidTrello";
// import MasterCard from "./MasterCard";

export interface IconInputProps {
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLOrSVGElement>) => void;
  target?: string;
  iconcolor?: string | null;
}

export * from "./DownloadCloudIcon";
export * from "./PdfIcon";
export * from "./DocxIcon";
export * from "./TextFileIcon";
export * from "./SrtFileIcon";
export * from "./TrashIcon";
export * from "./PauseCircleIcon";
export * from "./PlayIcon";
export * from "./RestartIcon";
export * from "./DeleteIcon";
export * from "./UndoIcon";
export * from "./RedoIcon";
export * from "./SkipBackIcon";
export * from "./PauseIcon";
export * from "./SkipForwardIcon";
export * from "./CompleteIcon";
export * from "./FailedIcon";
export * from "./InProgressIcon";
export * from "./PendingIcon";
export * from "./AINotesIcon";
export * from "./PlaybookIcons";

type svgIconProps = {
  iconName: string;
  className: string;
  onClick?: (e: React.MouseEvent<HTMLOrSVGElement>) => void;
  target?: string;
  iconcolor?: string;
};
const SvgIcon = ({
  iconName,
  className,
  onClick,
  target,
  iconcolor = "black",
}: svgIconProps) => {
  const iconProps = { className, onClick, target, iconcolor };

  const renderIcon = (iconName: string) => {
    switch (iconName) {
      case "DownloadIcon":
        return <DownloadIcon {...iconProps} />;
      case "PdfIcon":
        return <PdfIcon {...iconProps} />;
      case "DocxIcon":
        return <DocxIcon {...iconProps} />;
      case "TextFileIcon":
        return <TextFileIcon {...iconProps} />;
      case "SrtFileIcon":
        return <SrtFileIcon {...iconProps} />;
      case "DashboardIcon":
        return <DashboardIcon {...iconProps} />;
      case "DollarSign":
        return <SolidDollarSign {...iconProps} />;
      case "PatientPlusIcon":
        return <PatientPlusIcon {...iconProps} />;
      case "CompleteIcon":
        return <CompleteIcon {...iconProps} />;
      case "DeleteIcon":
        return <DeleteIcon {...iconProps} />;
      case "DeleteIconDark":
        return <DeleteIconDark {...iconProps} />;
      case "TrashSolidIcon":
        return <TrashSolidIcon {...iconProps} />;
      case "FailedIcon":
        return <FailedIcon {...iconProps} />;
      case "InProgressIcon":
        return <InProgressIcon {...iconProps} />;
      case "PauseCircleIcon":
        return <PauseCircleIcon {...iconProps} />;
      case "PauseIcon":
        return <PauseIcon {...iconProps} />;
      case "PendingIcon":
        return <PendingIcon {...iconProps} />;
      case "PlayIcon":
        return <PlayIcon {...iconProps} />;
      case "PlayIcon2":
        return <PlayIcon2 {...iconProps} />;
      case "RedoIcon":
        return <RedoIcon {...iconProps} />;
      case "RestartIcon":
        return <RestartIcon {...iconProps} />;
      case "SkipBackIcon":
        return <SkipBackIcon {...iconProps} />;
      case "SkipForwardIcon":
        return <SkipForwardIcon {...iconProps} />;
      case "StopIcon":
        return <StopIcon {...iconProps} />;
      case "StopIcon2":
        return <StopIcon2 {...iconProps} />;
      case "UndoIcon":
        return <UndoIcon {...iconProps} />;
      case "TrashIcon":
        return <TrashIcon {...iconProps} />;
      case "LockIcon":
        return <LockIcon {...iconProps} />;
      case "SettingIcon":
        return <SettingIcon {...iconProps} />;
      case "DoubleManIcon":
        return <DoubleManIcon {...iconProps} />;
      case "SingleManIcon":
        return <SingleManIcon {...iconProps} />;
      case "MicrophoneOffIcon":
        return <MicrophoneOffIcon {...iconProps} />;
      case "MicrophoneIcon":
        return <MicrophoneIcon {...iconProps} />;
      case "FilledOneManIcon":
        return <FilledOneManIcon {...iconProps} />;
      case "SolidDoubleManIcon":
        return <SolidDoubleIcon {...iconProps} />;
      case "SolidSettingIcon":
        return <SolidSettingIcon {...iconProps} />;
      case "SolidLockIcon":
        return <SolidLockIcon {...iconProps} />;
      case "VoiceNotesIcon":
        return <VoiceNotesIcon {...iconProps} />;
      case "VoiceNotesDarkIcon":
        return <VoiceNotesDarkIcon {...iconProps} />;
      case "AmplifierVoiceIcon":
        return <AmplifierVoiceIcon {...iconProps} />;
      case "MeetingIcon":
        return <MeetingIcon {...iconProps} />;
      case "ContentListIcon":
        return <ContentListIcon {...iconProps} />;
      case "PatientNotesIcon":
        return <PatientNotesIcon {...iconProps} />;
      case "ContentNotesIcon":
        return <ContentNotesIcon {...iconProps} />;
      case "ContentNotesDarkIcon":
        return <ContentNotesDarkIcon {...iconProps} />;
      case "CustomerSolidIcon":
        return <CustomerSolidIcon {...iconProps} />;
      case "Terminal":
        return <PromptsIcon {...iconProps} />;
      case "BoldIcon":
        return <BoldIcon {...iconProps} />;
      case "ItalicIcon":
        return <ItalicIcon {...iconProps} />;
      case "UnderlineIcon":
        return <UnderlineIcon {...iconProps} />;
      case "LeftalignIcon":
        return <LeftalignIcon {...iconProps} />;
      case "CenteralignIcon":
        return <CenteralignIcon {...iconProps} />;
      case "RightalignIcon":
        return <RightalignIcon {...iconProps} />;
      case "JustifyalignIcon":
        return <JustifyalignIcon {...iconProps} />;
      case "H1Icon":
        return <H1Icon {...iconProps} />;
      case "H2Icon":
        return <H2Icon {...iconProps} />;
      case "H3Icon":
        return <H3Icon {...iconProps} />;
      case "H4Icon":
        return <H4Icon {...iconProps} />;
      case "BulletLineIcon":
        return <BulletLineIcon {...iconProps} />;
      case "OrderListIcon":
        return <OrderListIcon {...iconProps} />;
      case "CodeBlockIcon":
        return <CodeBlockIcon {...iconProps} />;
      case "BlockquoteIcon":
        return <BlockquoteIcon {...iconProps} />;
      case "HorizontalRuleIcon":
        return <HorizontalRuleIcon {...iconProps} />;
      case "UndoIcon2":
        return <UndoIcon2 {...iconProps} />;
      case "ParagraphIcon":
        return <ParagraphIcon {...iconProps} />;
      case "FolderIcon":
        return <Folder color={iconcolor ?? ""} {...iconProps} />;
      case "slack":
        return <Slack {...iconProps} />;
      case "AINotesIcon":
        return <AINotesIcon {...iconProps} />;
      case "Clock":
        return <Clock {...iconProps} />;
      case "visa":
        return <Visa {...iconProps} />;
      case "american_express":
        return <AmericanExpress {...iconProps} />;
      case "jcb": 
        return <JCB {...iconProps} />
      case "discover":
        return <Discover {...iconProps} />
      case "mastercard":
        return <MasterCard {...iconProps} />
      case "union_pay":
        return <UnionPay {...iconProps} />
      case "diners_club":
        return <Dinners {...iconProps} />
      case "credit-card":
        return <CreditCard {...iconProps} />
      case "trello":
        return <SolidTrelloIcon {...iconProps} />
      default:
        return <></>;
    }
  };
  return <div>{renderIcon(iconName)}</div>;
};
export default SvgIcon;
