// ** hooks **
import { AxiosRequestConfig } from "axios";
import { useAxiosGet, useAxiosPost } from "hooks/useaxios";

const ADMIN_API_BASE_PATH = "/auth";
const All_PLANS_BASE_PATH = "plans";

export const useAddSuperUserAPI = () => {
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPost();
  const addSuperUserAPI = async (
    data: object,
    config: AxiosRequestConfig<object> = {}
  ) => {
    return callApi(`${ADMIN_API_BASE_PATH}/create_superuser`, data, config);
  };
  return { addSuperUserAPI, isLoading, isError, isSuccess };
};

export const useSuperUserListAPI = () => {
  // ** Custom Hooks **
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosGet();

  const getSuperUserListApi = async (data: object) => {
    return callApi(`${ADMIN_API_BASE_PATH}/get_all_superusers`, data);
  };

  return { getSuperUserListApi, isLoading, isError, isSuccess };
};

export const useGetAllPaymentHistoryAPI = () => {
  // ** Custom Hooks to Get All Payment History **
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosGet();

  const getAllPaymentHistoryApi = async (data: object) => {
    return callApi(`${All_PLANS_BASE_PATH}/get_all_payment_history`, data);
  };

  return { getAllPaymentHistoryApi, isLoading, isError, isSuccess };
};
