import { BREAD_CRUMB } from "constants/breadcrumbs.constant";
import AdminPlans from "./AdminPlans";
import Breadcrumbs from "components/Breadcrumbs";

const AllAdminPlans = () => {
  return (
    <>
      {/* <Breadcrumbs path={BREAD_CRUMB.PlanManagement} /> */}
      <AdminPlans />
    </>
  );
};

export default AllAdminPlans;
