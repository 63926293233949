//  ** packages **
import React, { Suspense } from "react";
import {
  RouteObject,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";

// components **
import PageLoader from "components/Loaders/PageLoader";
import TranscriptShared from "components/TransShared";
import PatientRecording from "modules/Patient/sub-modules/Recording/PatientRecording";
import PatientTable from "modules/Patient/components/PatientTable";
import AppointmentScheduleDetail from "modules/Patient/sub-modules/PatientDetails/components/AppoinmentScheduleDetail";
import PermissionGuard from "modules/Auth/components/PermissionGuard";
import Summary from "modules/Patient/sub-modules/Summary";
import Notes from "modules/DashBoard/sub-modules/Notes";
import RequiresUnAuth from "./modules/Auth/components/RequiresUnAuth";
import RequiresAuth from "./modules/Auth/components/RequireAuth";

// ** constants **
import {
  PRIVATE_NAVIGATION,
  PUBLIC_NAVIGATION,
} from "./constants/navigation.constant";
import { PERMISSIONS } from "constants/Permissions.constant";

// ** routes **
import adminRoutes from "modules/Admin/route";
import AuthenticationRoutes from "./modules/Auth/routes";

import SettingRoutes from "modules/Setting/route";
import NotFoundPage from "modules/Auth/pages/NotFound";
import PaymentFailure from "modules/Setting/sub-modules/PaymentSetting/components/PaymentFailed";
// import InvoiceList from "modules/Setting/sub-modules/PaymentSetting/components/Billings";
import BillingDetails from "modules/Setting/sub-modules/PaymentSetting/components/BillingDetails";
import SubscriptionPlan from "modules/Setting/sub-modules/PaymentSetting/components/SubscriptionPlan";

// ** reset password **
const ResetPassword = React.lazy(
  () => import("./modules/Auth/pages/ResetPassword")
);

// ** set password **
const SetPassword = React.lazy(
  () => import("./modules/Auth/pages/ResetPassword/setPassword")
);
// ** recording **
const Recording = React.lazy(
  () => import("./modules/Patient/sub-modules/Recording")
);

// ** not found **
const NotFound404 = React.lazy(
  () => import("./components/Theme/NoDataFound/NotFound")
);

// ** VerifyAccount **
const VerifyAccount = React.lazy(
  () => import("./modules/Auth/pages/VerifyEmail")
);

// ** Patient schedule **
const PatientSchedule = React.lazy(() => import("./modules/Patient"));

// ** Dashboard **

const Dashboard = React.lazy(() => import("./modules/DashBoard"));

// ** transcript **
const TranscriptMain = React.lazy(() => import("./components/TranscriptMain"));

// ** patient details **
const PatientDetails = React.lazy(
  () => import("../src/modules/Patient/sub-modules/PatientDetails")
);

// ** Meeting details **
const MeetingIndex = React.lazy(() => import("../src/modules/MeetingFolder"));
const MeetingDetails = React.lazy(
  () => import("../src/modules/MeetingFolder/components/MeetingFolderDetails")
);
const MeetingRecording = React.lazy(
  () => import("../src/modules/MeetingFolder/components/MeetingRecording")
);

// ** transaction successful **
const PaymentSuccess = React.lazy(
  () =>
    import(
      "modules/Setting/sub-modules/PaymentSetting/components/PaymentSuccess"
    )
);

//** transaction failed **
// const PaymentFailed = React.lazy(
//   () => import("modules/Setting/sub-modules/PaymentSetting/components/PaymentFailure")

// );

// ** notAuthorized **
const NotAuthorized = React.lazy(
  () => import("./modules/Auth/pages/NotAuthorized")
);

// ** requested limit is over //
const LimitAlert = React.lazy(() => import("./components/LimitAlert/index"));

// ** requested page not found //
// const NotFoundPage = React.lazy(
//   () => import("./")
// );

//  ** type **
export type RouteObjType = {
  path?: string;
  element: JSX.Element;
  children?: RouteObject[];
  errorElement?: JSX.Element;
};
const applySuspense = (routes: RouteObjType[]): RouteObjType[] => {
  return routes.map((route) => ({
    ...route,
    element: <Suspense fallback={<PageLoader />}>{route.element}</Suspense>,
  }));
};
export const applyRequiresAuth = (routes: RouteObjType[]): RouteObjType[] => {
  return routes.map((route) => ({
    ...route,
    element: <RequiresAuth>{route.element}</RequiresAuth>,
  }));
};
const Routes = () => {
  const routesForNotAuthenticatedOnly: RouteObjType[] = applySuspense([
    {
      element: <RequiresUnAuth />,
      children: AuthenticationRoutes,
    },
  ]);

  const routesForAuthenticatedOnly: RouteObjType[] = applyRequiresAuth([
    {
      path: PRIVATE_NAVIGATION.dashboard.view,
      element: <Dashboard />,
    },
    // ** patient module route **
    {
      path: PRIVATE_NAVIGATION.patient.view,
      element: (
        <PermissionGuard permission={PERMISSIONS.PATIENT.READ}>
          {" "}
          <PatientTable />{" "}
        </PermissionGuard>
      ),
    },
    // {
    //   path: PRIVATE_NAVIGATION.patient.detail.appointment,
    //   element: <PatientSchedule />,
    //   errorElement: <ErrorBoundary />,
    // },
    {
      path: PRIVATE_NAVIGATION.transcription.view,
      element: (
        <PermissionGuard permission={PERMISSIONS.NOTES.CREATE}>
          {" "}
          <TranscriptMain />
        </PermissionGuard>
      ),
    },

    {
      path: PRIVATE_NAVIGATION.transcription.detail.view(),
      element: (
        <PermissionGuard permission={PERMISSIONS.NOTES.READ}>
          {" "}
          <TranscriptMain />
        </PermissionGuard>
      ),
    },

    {
      path: PRIVATE_NAVIGATION.patient.detail.view(),
      element: (
        <PermissionGuard permission={PERMISSIONS.PATIENT.READ}>
          {" "}
          <PatientDetails />{" "}
        </PermissionGuard>
      ),
    },

    {
      path: PRIVATE_NAVIGATION.patient.recording(),
      element: (
        <PermissionGuard permission={PERMISSIONS.NOTES.CREATE}>
          {" "}
          <PatientRecording />{" "}
        </PermissionGuard>
      ),
    },
    {
      path: PRIVATE_NAVIGATION.patient.appointmentDetail(),
      element: <AppointmentScheduleDetail />,
    },
    {
      path: PRIVATE_NAVIGATION.recording.view,
      element: <Recording />,
    },
    {
      path: PRIVATE_NAVIGATION.notes.view,
      element: (
        <PermissionGuard permission={PERMISSIONS.NOTES.READ}>
          {" "}
          <Notes />{" "}
        </PermissionGuard>
      ),
    },
    {
      path: PRIVATE_NAVIGATION.content.view,
      element: (
        <PermissionGuard permission={PERMISSIONS.NOTES.READ}>
          <Notes currentNoteType="content" />
        </PermissionGuard>
      ),
    },
    {
      path: PRIVATE_NAVIGATION.voice.view,
      element: (
        <PermissionGuard permission={PERMISSIONS.NOTES.READ}>
          <Notes currentNoteType="voice" />
        </PermissionGuard>
      ),
    },
    {
      path: PRIVATE_NAVIGATION.MeetingFolder.detail.view(),
      element: (
        <PermissionGuard permission={PERMISSIONS.NOTES.READ}>
          <MeetingDetails />
        </PermissionGuard>
      ),
    },
    {
      path: PRIVATE_NAVIGATION.MeetingFolder.view,
      element: (
        <PermissionGuard permission={PERMISSIONS.NOTES.READ}>
          <MeetingIndex />
        </PermissionGuard>
      ),
    },
    {
      path: PRIVATE_NAVIGATION.MeetingFolder.recording(),
      element: (
        <PermissionGuard permission={PERMISSIONS.NOTES.CREATE}>
          <MeetingRecording />
        </PermissionGuard>
      ),
    },
    {
      path: PRIVATE_NAVIGATION.notFoundPage.view,
      element: <NotFound404 />,
    },
    // transaction routes
    {
      path: PRIVATE_NAVIGATION.transactions.viewSuccess.view,
      element: (
        // <PermissionGuard permission={PERMISSIONS.SUBSCRIPTION.CREATE}>
        <PaymentSuccess />
        // </PermissionGuard>
      ),
    },
    {
      path: PRIVATE_NAVIGATION.transactions.viewFailure.view,
      element: (
        <PermissionGuard permission={PERMISSIONS.SUBSCRIPTION.CREATE}>
          <PaymentFailure />
        </PermissionGuard>
      ),
    },
    // {
    //   path: PRIVATE_NAVIGATION.transactions.viewInvoiceList.view,
    //   element: <InvoiceList />,
    // },
    {
      path: PRIVATE_NAVIGATION.setting.billingDetails.view,
      element: (
        <PermissionGuard permission={PERMISSIONS.SUBSCRIPTION.CREATE}>
          <BillingDetails />
        </PermissionGuard>
      ),
    },
    ...SettingRoutes,
  ]);
  const routesForPublic: RouteObjType[] = [
    {
      path: PUBLIC_NAVIGATION.subscription,
      element: (
        <PermissionGuard
          permission={PERMISSIONS.SUBSCRIPTION.CREATE}
          subUrl="subscription"
        >
          <SubscriptionPlan />
        </PermissionGuard>
      ),
    },
    {
      path: PUBLIC_NAVIGATION.resetPassword,
      element: (
        <Suspense>
          <ResetPassword />
        </Suspense>
      ),
    },
    {
      path: PUBLIC_NAVIGATION.setPassword,
      element: (
        <Suspense>
          <SetPassword />
        </Suspense>
      ),
    },
    {
      path: PUBLIC_NAVIGATION.verifyAccount,
      element: (
        <Suspense>
          <VerifyAccount />
        </Suspense>
      ),
    },
    {
      path: PUBLIC_NAVIGATION.notAuthorized,
      element: (
        <Suspense>
          <NotAuthorized />
        </Suspense>
      ),
    },
    {
      path: PUBLIC_NAVIGATION.toomanyrequest,
      element: (
        <Suspense>
          <LimitAlert />
        </Suspense>
      ),
    },
    {
      path: PUBLIC_NAVIGATION.sharedTranscription(),
      element: (
        <Suspense>
          <TranscriptShared />
        </Suspense>
      ),
    },
  ];
  const notFound: RouteObject[] = [
    {
      path: "*",
      element: (
        <RequiresAuth>
          <NotFoundPage />
        </RequiresAuth>
      ),
    },
  ];

  // route combination of authorize and unauthorize
  const router = createBrowserRouter([
    ...routesForPublic,
    ...routesForNotAuthenticatedOnly,
    ...routesForAuthenticatedOnly,
    ...notFound,
    ...adminRoutes,
  ]);
  return <RouterProvider router={router} />;
};
export default Routes;
