// ** Packages **
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { Suspense } from "react";
// **  component **
import PageLoader from "components/Loaders/PageLoader";
// ** Redux **
import { getAuth } from "redux/slices/authSlice";
// ** constant **

import { PRIVATE_NAVIGATION, PUBLIC_NAVIGATION } from "constants/navigation.constant";
const RequiresUnAuth = () => {
  // ** Hooks **
  const { isAuthenticated,user } = useSelector(getAuth);

  if (isAuthenticated) {
    return <Navigate to={user?.current_plan?.active ?  PRIVATE_NAVIGATION.dashboard.view : PUBLIC_NAVIGATION.subscription} />;
    // return <Navigate to={user?.current_plan?.active ?  PRIVATE_NAVIGATION.dashboard.view : PRIVATE_NAVIGATION.setting.subscription.view} />;
  }

  return (
    <Suspense fallback={<PageLoader />}>
      <Outlet />
    </Suspense>
  );
};

export default RequiresUnAuth;
