// ** packages **
import { useEffect, useMemo, useState } from "react";
import { MoreVertical } from "react-feather";
import { format as Format } from "date-fns";

// ** components **
import TableCellRender from "components/DataTable/components/TableCellRender";
import TippyDropdown from "components/TippyDropDown";
// ** helper **
import { renderOptions } from "components/TippyDropDown/helper";
// **  constant **
import { firstCharUpperCase } from "utils";

// ** store **
import store from "redux/store";
import usePermission from "hooks/usePermission";
import { PERMISSIONS } from "constants/Permissions.constant";
import DateFormat from "components/DateFormat";
import { useSelector } from "react-redux";
import { getAuth } from "redux/slices/authSlice";
import { classNames } from "components/Tab";

const PlayBookColumn = (props: any) => {
  const { setIsOpen, onChangeStatus } = props;
  const storeData = store.getState();
  const is_super_user = storeData?.auth?.user?.is_super_administrator;
  const { hasPermission } = usePermission();
  const { user } = useSelector(getAuth);
  const playbookReadPermission = hasPermission(PERMISSIONS.PLAYBOOK.READ);
  const playbookUpdatePermission = hasPermission(PERMISSIONS.PLAYBOOK.UPDATE);
  const playbookDeletePermission = hasPermission(PERMISSIONS.PLAYBOOK.DELETE);

  const [windowSize, setWindowSize] = useState(window.innerWidth);

  const handleResize = () => {
    if (
      (window.innerWidth > 1100 && windowSize < 1100) ||
      (window.innerWidth < 1100 && windowSize >= 1100)
    ) {
      setWindowSize(window.innerWidth);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [windowSize]);

  const columnDefs = useMemo(
    () => [
      windowSize >= 1100
        ? {
          name: "Title",
          sortField: "title",
          // width: "12%",
          selector: (row: { title: string }) => row.title,
          sortable: true,
          cell: (row: { created_at: string; id: number; static: boolean; title: string }) => (
            <TableCellRender
              className="h-full w-full"
              key={row.created_at}
              cellRowData={<div className="w-full h-full cursor-pointer content-center" onClick={
                () => {
                  setIsOpen({
                    open: true,
                    id: row.id,
                    isDefault: row?.static ?? false,
                    viewOnly: playbookUpdatePermission,
                  });
                }
              }>{row.title}</div>}
            />
          ),
        }
        : {
          name: "Name",
          selector: (row: { title: string }) => row?.title,
          sortable: false,
          sortField: "file_name",
          cell: (row: {
            title: string;
            created_at: string;
            created: { first_name: string; last_name: string };
            is_active: boolean;
          }) => (
            <TableCellRender
              cellRowData={
                <>
                  <div className="ttttttt px-4 py-8 cursor-pointer">
                    <div className="flex gap-2 items-center">
                      <span className="font-medium text-gray-900 truncate">
                        {row?.title}
                      </span>
                      <span className="flex gap-2 text-gray-500">
                        {" "}
                        &#x2022;{" "}
                      </span>
                      <span
                        className={
                          row.is_active ? "text-sky-600 whitespace-nowrap" : "text-red-700 whitespace-nowrap"
                        }
                      >
                        {firstCharUpperCase(
                          row.is_active ? "Active" : "Inactive"
                        )}
                      </span>
                    </div>
                    <div className="lg:ml-0 lg:mt-1 break-all">
                      <div className="flex gap-2 items-center text-gray-500">
                        <span>
                          {row?.created &&
                            `Uploaded by ${firstCharUpperCase(
                              row?.created?.first_name
                            )} ${firstCharUpperCase(
                              row?.created?.last_name
                            )}`}{" "}
                          &#x2022;{" "}
                          {row.created_at && (
                            <DateFormat
                              date={row.created_at}
                              format="dd MMM yyyy"
                            />
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              }
            />
          ),
        },
      windowSize > 1100 && {
        name: "Created Date",
        // width: "10%",
        sortField: "created_at",
        selector: (row: { created_at: string }) => row.created_at,
        sortable: true,
        cell: (row: { created_at: string }) => (
          <TableCellRender
            key={row.created_at}
            cellRowData={Format(new Date(row.created_at), "MM/dd/yyyy")}
          />
        ),
      },
      windowSize > 1100 && {
        name: "Created by",
        // width: "12%",
        sortField: "email",
        selector: (row: { created: { email: string | null } }) =>
          row?.created?.email,
        sortable: false,
        cell: (row: {
          created: { email: string | null };
          static: boolean | null;
        }) => (
          <TableCellRender
            key={row.created?.email}
            cellRowData={row?.static ? "System Default" : (row?.created?.email || "-")}
          />
        ),
      },
      windowSize > 1100 && {
        name: "Status",
        width: "10%",
        sortField: "is_active",
        selector: (row: { is_active: boolean }) => row.is_active,
        sortable: false,
        cell: (row: {
          id: number | string;
          is_active: boolean;
          is_draft: boolean;
        }) => (
          <TableCellRender
            key={row.id}
            className={row.is_active ? "text-sky-600" : "text-red-700"}
            cellRowData={firstCharUpperCase(
              row.is_active ? "Active" : "Inactive"
            )}
          />
        ),
      },
      {
        name: "Action",
        selector: (row: { id: number }) => row.id,
        sortable: false,
        width: windowSize > 1100 ? "10%" : null,
        cell: (row: {
          id: number;
          static: boolean | null;
          is_active: boolean;
          is_draft: boolean;
          created: { email: string | null; };
        }) => {
          const PlaybookAdd: {
            label: string;
            onClick: () => void;
          }[] = [];

          (row?.static && !is_super_user) ||
            (row.static && playbookReadPermission) ? (
            <></>
          ) : (
            PlaybookAdd.push({
              label: "View Playbook",
              onClick: () => {
                setIsOpen({
                  open: true,
                  id: row.id,
                  isDefault: row?.static ?? false,
                  viewOnly: playbookUpdatePermission,
                });
              },
            })
          );

          PlaybookAdd.push({
            label: row.is_active ? "Inactive" : "Active",
            onClick: () => {
              onChangeStatus(
                String(row.id),
                "status",
                row.is_active ? "Active" : "Inactive",
                row?.static
              );
            },
          });

          ((!row?.static && user?.email === row?.created?.email) || is_super_user) &&
            PlaybookAdd.push({
              label: "Delete Playbook",
              onClick: () => {
                onChangeStatus(row?.id, "delete", null, row?.static);
              },
            });
          return (
            <TippyDropdown
              content={({ close }) =>
                renderOptions({ close, filedArray: PlaybookAdd })
              }
            >
              <MoreVertical className="cursor-pointer" />
            </TippyDropdown>
          );
        },
      },
    ],
    [windowSize]
  );
  return columnDefs;
};

export default PlayBookColumn;
