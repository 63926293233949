// ** packages **
import * as yup from "yup";
// ** error message  **
import { addPatientModelSchemaError } from "constants/formErrorMessage.constant";
import { TableRefType } from "components/DataTable";

export const addMeetingSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required(addPatientModelSchemaError.name.name)
    .min(3, addPatientModelSchemaError.name.minLengthName)
    .max(75, addPatientModelSchemaError.name.maxLengthName),
});

export type meetingAllNotesTypeProps = {
  meetingId: string | number | undefined;
  tableRef: React.RefObject<TableRefType>;
};

export type meetingSideBarPropsType = {
  meetingId: number| string;
  setIsSideOpen: React.Dispatch<React.SetStateAction<boolean>>;
  tableRef: React.RefObject<TableRefType>;
  isSideOpen: boolean;
};
