// ** Packages **
import { Store } from "@reduxjs/toolkit";
import axios from "axios";
import { REACT_APP_API_URL } from "config";
import {
  PRIVATE_NAVIGATION,
  PUBLIC_NAVIGATION,
} from "constants/navigation.constant";
import { setLogoutData } from "redux/slices/authSlice";
import { removeToast, setToast } from "redux/slices/toastSlice";
import { convertTitleCase } from "utils";
export const Axios = axios.create({ baseURL: `${REACT_APP_API_URL}` });
export const setupAxios = (store: Store) => {
  // set token in header
  Axios.interceptors.request.use((request) => {
    const authToken = localStorage.getItem("access_token");
    if (request.headers !== undefined && authToken) {
      request.headers.Authorization = `Bearer ${authToken}`;
    }

    return request;
  });

  // dynamic  for toast message setup
  Axios.interceptors.response.use(
    async (res) => {
      const { toast } = res.data;
      if (toast) {
        // ----------- set api response toast -----------
        if (typeof res.data.message === "string") {
          const toastId = new Date().getTime();
          store.dispatch(
            setToast({
              message: res.data.message,
              type: res.data.responseType,
              id: toastId,
            })
          );
          setTimeout(() => {
            store.dispatch(removeToast({ id: toastId }));
          }, 2000);
        } else {
          Object.keys(res.data.message).forEach((key) => {
            const capitalName = convertTitleCase(key);
            const errorMessage = `${capitalName} : ${res.data.message[key]?.[0]}`;
            const toastId = new Date().getTime();
            store.dispatch(
              setToast({
                message: errorMessage,
                type: res.data.responseType,
                id: toastId,
              })
            );
            setTimeout(() => {
              store.dispatch(removeToast({ id: toastId }));
            }, 2000);
          });
        }
      }
      return res.data;
    },
    async (e) => {
      if (e.response.status === 401) {
        const storeData = store.getState();
        if (storeData.token !== null) {
          store.dispatch(setLogoutData());
        }
        window.location.href = PUBLIC_NAVIGATION.login + `?next=${window?.location?.pathname}`;
      }
      if (e.response.status === 404) {
        const storeData = store.getState();
        window.location.href = storeData?.auth?.user?.is_super_administrator
          ? PRIVATE_NAVIGATION.adminNotFoundPage
          : PRIVATE_NAVIGATION.notFoundPage.view;
        await new Promise((res) => {
          setTimeout(() => {
            res(true);
          }, 100);
        });
        return;
      }
      if (
        e.response.status === 403 &&
        e.response.data.message === "You do not have access to this resource"
      ) {
        window.location.href = PUBLIC_NAVIGATION.notAuthorized;
        await new Promise((res) => {
          setTimeout(() => {
            res(true);
          }, 100);
        });
        return;
      }

      if (e.response.status === 500 || e.response.status === 400) {
        const { toast } = e.response.data;
        if (toast) {
          // ----------- set api response toast -----------
          const toastId = new Date().getTime();
          store.dispatch(
            setToast({
              message: e.response.data.message,
              type: e.response.data.responseType,
              id: toastId,
            })
          );
          setTimeout(() => {
            store.dispatch(removeToast({ id: toastId }));
          }, 2000);
        }
      }


    
      if (e.response.status === 429) {
        window.location.href = PUBLIC_NAVIGATION.toomanyrequest;
          await new Promise((res) => {
            setTimeout(() => {
              res(true);
            }, 100);
          });
          return;
        }
    

      throw e.response.data;
    }
  );
};

export default axios;
