// ** Packages **
import { Navigate, useLocation } from "react-router-dom";

// ** Constants **
import {
  PRIVATE_NAVIGATION,
  PUBLIC_NAVIGATION,
} from "constants/navigation.constant";
import usePermission from "hooks/usePermission";
import React from "react";
import { useSelector } from "react-redux";
import { getAuth } from "redux/slices/authSlice";
import store from "redux/store";
import { removeToast, setToast } from "redux/slices/toastSlice";

type Props = {
  children: JSX.Element | React.ReactNode;
  permission?: {
    type: string;
    name: string;
  };
  subUrl?: string;
  wantSuper?: boolean;
};

const PermissionGuard = (props: Props) => {
  const { user } = useSelector(getAuth);

  const { children, permission, subUrl, wantSuper } = props;
  const location = useLocation();
  const { hasPermission } = usePermission();
  if (
    user?.current_plan?.active &&
    (subUrl === "billing" || subUrl === "subscription") &&
    !wantSuper &&
    user?.current_plan?.payment_data?.plan?.price === 0 &&
    !user?.current_plan?.payment_data?.plan?.is_admin_show
  ) {
    return (
      <Navigate
        to={PRIVATE_NAVIGATION.dashboard.view}
        state={{ from: location }}
      />
    );
  }
  if (permission && !hasPermission(permission)) {
    return (
      <Navigate
        to={PRIVATE_NAVIGATION.dashboard.view}
        state={{ from: location }}
      />
    );
  }

  if (!user?.current_plan?.active && subUrl === "subscription" && !wantSuper) {
    return <> {children}</>;
  }

  if (!user?.current_plan?.active && !wantSuper) {
    store.dispatch(removeToast({ id: 470000004 }));
    store.dispatch(
      setToast({
        message: "Plan has been expired!",
        type: "error",
        id: 470000004,
      })
    );
    setTimeout(() => {
      store.dispatch(removeToast({ id: 470000004 }));
    }, 2000);

    return (
      <Navigate
        to={PUBLIC_NAVIGATION.subscription}
        // to={PRIVATE_NAVIGATION.setting.subscription.view}
        state={{ from: location }}
      />
    );
  }

  return <> {children}</>;
};

export default PermissionGuard;
