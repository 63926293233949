// ** packages **
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// ** redux **
import {
  getAuth,
  setAuthInitialized,
  setAuthenticated,
  setCurrentUser,
} from "redux/slices/authSlice";

// ** service **
import { useLoggedUserAPI } from "../services";

const useAuthGuard = () => {
  // ** Hooks **
  const dispatch = useDispatch();
  const { isAuthenticated, isAuthInitialized } = useSelector(getAuth);

  // ** APIS **
  const { loggedUserAPI, isLoading } = useLoggedUserAPI();

  useEffect(() => {
    loadUser();
  }, []);

  const loadUser = async () => {
    if (!isAuthenticated && !isAuthInitialized) {
      const token = localStorage.getItem("access_token");
      if (token) {
        const { data, error } = await loggedUserAPI({})
        
        if (!error && data) {
          const user = {
            firstName: data.first_name,
            lastName: data.last_name,
            phoneNumber: data.contact_number,
            email: data.email,
            profile: data.profile_image,
            permissions: data.permissions,
            is_super_administrator: data.is_super_administrator,
            roles:data.roles,
            current_plan: data.current_plan,
          };
          dispatch(setCurrentUser({ user }));
          dispatch(
            setAuthenticated({
              isAuthenticated: true,
            })
          );
        }
      }
      dispatch(setAuthInitialized());
    }
  };
  return {
    isLoading,
    isAuthenticated,
    isAuthInitialized,
  };
};

export default useAuthGuard;
