// ** packages **
import { useCallback, useEffect, useRef, useState } from "react";
import { Search, Plus } from "react-feather";
import { debounce } from "lodash";
import { useLocation, useNavigate } from "react-router-dom";

// ** components **
import Breadcrumbs from "components/Breadcrumbs";
import DataTableReact, { TableRefType } from "components/DataTable";
import SvgIcon from "components/Icon/SvgIcon";
import NoteSideBar from "./components/NoteSideBar";

// ** hooks **
import useNotesColumn from "./hooks/useNotesColumn";
import usePermission from "hooks/usePermission";

// ** services **
import { useAllTranscriptListAPI } from "./services";

// ** constants **
import { BREAD_CRUMB } from "constants/breadcrumbs.constant";
import { PERMISSIONS } from "constants/Permissions.constant";
import SelectMenu, { optionsType } from "components/SelectMenu";
import { PRIVATE_NAVIGATION } from "constants/navigation.constant";
import AlertModal from "components/modal/AlertModal";
import { useDeleteTranscriptAPI } from "modules/Patient/sub-modules/Transcription/services";
import { useSelector } from "react-redux";
import { getAuth } from "redux/slices/authSlice";
import store from "redux/store";
import { removeToast, setToast } from "redux/slices/toastSlice";

type currentNoteType = "patient" | "content" | "voice" | "meeting";

export const NoteOptions: optionsType[] = [
  {
    id: 1,
    title: "Patient",
    description: "",
  },
  {
    id: 2,
    title: "Content",
    description: "",
  },
  {
    id: 3,
    title: "Voice",
    description: "",
  },
  {
    id: 4,
    title: "Meeting",
    description: "",
  },
];

const Notes = (props: { currentNoteType?: currentNoteType }) => {
  const { currentNoteType } = props;
  const { hasPermission } = usePermission();
  const navigate = useNavigate();
  const tableRef = useRef<TableRefType>(null);
  const [isSideOpen, setIsSideOpen] = useState<boolean>(false);
  const { state } = useLocation();
  const noteCreatePermission = hasPermission(PERMISSIONS.NOTES.CREATE);
  const [currentNote, setCurrentNote] = useState<currentNoteType>(
    currentNoteType ? currentNoteType : state?.note_type
  );
  const [open, setOpen] = useState(false);
  const [currentID, setCurrentID] = useState<number | string>(0);
  const { getAllTranscriptListAPI, isLoading } = useAllTranscriptListAPI();
  const { deleteTranscriptAPI } = useDeleteTranscriptAPI();
  const [throttleRecordCount, setThrottleRecordCount] = useState<number>(0);

  const { user } = useSelector(getAuth);
  const plan = user?.current_plan?.payment_data?.plan;
  const planName = plan?.plan_name?.toLocaleLowerCase();
  const specification = plan?.specification;
  let limit = 0;
  if (specification) {
    specification?.forEach((name: any, i) => {
      if (
        name?.name_type?.toLocaleLowerCase() === "transcript" &&
        name?.limit
      ) {
        limit = name?.limit;
        return;
      }
    });
  }
  if (planName === "premium") {
    limit = -1;
  }

  const getTableData = async (params: {
    page: number;
    perPage: number;
    sortField: string;
    sortOrder: string;
    search: string;
  }) => {
    let tableData = { rowData: [], rowCount: 10 };
    const { data, error } = await getAllTranscriptListAPI({
      params: {
        page: params.page,
        per_page: params.perPage,
        sort_by: params?.sortField,
        sort_order: params?.sortOrder,
        search: params.search,
        types: currentNote ?? "patient",
      },
    });

    if (data && !error) {
      setThrottleRecordCount(data?.usage_count);
      tableData = {
        rowData: data?.data || [],
        rowCount: data?.count || 0,
      };
    }
    return tableData;
  };
  const onDeleteClick = async (id: number | string) => {
    setOpen(true);
    setCurrentID(id);
  };
  const refreshTable = () => {
    tableRef.current?.refreshData();
  };
  const onDeleteSubmit = async (id: number | string) => {
    await deleteTranscriptAPI(id);
    refreshTable();
  };

  const onClose = () => {
    setOpen(false);
  };
  const columnDefs = useNotesColumn({ currentNote, tableRef, onDeleteClick });
  useEffect(() => {
    if (currentNoteType) {
      setCurrentNote(currentNoteType);
    }
  }, [currentNoteType]);

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    tableRef.current?.onChange?.(e);
  };
  const dataTable = useCallback(() => {
    return (
      <DataTableReact
        setTableLength={null}
        key={currentNote ?? "patient"}
        ref={tableRef}
        columns={columnDefs}
        loader={isLoading}
        getData={(params: {
          page: number;
          perPage: number;
          sortField: string;
          sortOrder: string;
          search: string;
        }) => getTableData(params)}
      />
    );
  }, [currentNote]);

  const onSelect = (value: optionsType) => {
    if (value?.title) {
      setCurrentNote(value?.title?.toLowerCase() as currentNoteType);
      navigate(PRIVATE_NAVIGATION.notes.view, {
        state: {
          note_type: value?.title?.toLowerCase() as currentNoteType,
          navHighlighter: value?.title?.toLowerCase() as currentNoteType,
        },
      }); // this navigate added to change active part of
    }
  };

  return (
    <div>
      <NoteSideBar
        setIsSideOpen={setIsSideOpen}
        tableRef={tableRef}
        isSideOpen={isSideOpen}
        note_type={currentNote ?? "patient"}
      />
      <div>
        <Breadcrumbs path={BREAD_CRUMB.Notes[currentNote ?? "patient"]} />
      </div>
      <div className="flex sm:flex-col items-center sm:items-start justify-between mt-[15px] mb-[15px]">
        <div className="form__group sm:mb-3">
          <div className="field__wrapper mb-0">
            <div className="field__inner__wrapper field__has__icon icon__position__left">
              <input
                className="input__DSD"
                type="text"
                placeholder="Search"
                onChange={debounce(onSearchChange, 300)}
              />
              <div className="icon__wrapper">
                <Search />
              </div>
            </div>
          </div>
        </div>

        <div className="flex items-center pr-[10px] ml-2 sm:ml-0">
          {/* {(throttleRecordCount < +limit || limit === -1) && (
            <>
              {alert(
                "You exeeded the limit you won't be able to save the recording!!!"
              )}
            </>
          )} */}
          {/* {(throttleRecordCount < +limit || limit === -1)
          && */}
          <SelectMenu
            publishingOptions={NoteOptions}
            onSelect={onSelect}
            title={noteCreatePermission ? "New Note" : undefined}
            titleIcon={
              noteCreatePermission ? (
                <Plus className="inline mx-1 size-sm" />
              ) : undefined
            }
            wantCheckInTitle={!noteCreatePermission}
            needHover={false}
            selected={{ id: null, title: currentNote ?? "patient" }}
            wantTickFromStatus={false}
            onTextClick={() => {
              if (+limit <= throttleRecordCount && +limit !== -1) {
                // onChange={(e) => {
                //   if (e.target.value.length <= 25) {
                //     onChangeTitle(e);
                //   } else {
                store.dispatch(removeToast({ id: 470000010 }));
                store.dispatch(
                  setToast({
                    message:
                      "Your Limit has been exhusted! Your Recording cannot Be Saved.",
                    type: "error",
                    id: 470000010,
                  })
                );
                setTimeout(() => {
                  store.dispatch(removeToast({ id: 470000010 }));
                }, 2000);
                //   }
                // }}
              }
              setIsSideOpen(true);
            }}
            titleDivClass="cursor-pointer"
          />
          {/* } */}
        </div>
      </div>
      <div className="reactDataTable__DSD notesTable user-settings">
        {dataTable()}
      </div>
      <AlertModal
        visible={open}
        onClose={onClose}
        onCancel={onClose}
        onSubmit={() => {
          onDeleteSubmit(currentID);
          setOpen(false);
        }}
        submitButtonText="Yes"
      >
        <p className="text-[18px] font-Biotif__SemiBold text-textDark text-center w-[300px] max-w-full mx-auto">
          "Are you sure you want to delete Recording ?"
        </p>
      </AlertModal>
    </div>
  );
};
export default Notes;
