import { ArrowLeft, Clipboard, Edit, Link2 } from "react-feather";
import { BubbleMenu, EditorContent, useEditor } from "@tiptap/react";
import { CommandListType, CommandTypes, Commands } from "./suggestion/Commands";
import { Editor, createDocument, posToDOMRect } from "@tiptap/core";
import { EventHandler, useCallback, useEffect, useRef, useState } from "react";
import { headingGroup, textAlignGroup } from "./OptionGroups";
import { removeToast, setToast } from "redux/slices/toastSlice";
import Button from "components/Theme/Button";
import { Color } from "@tiptap/extension-color";
import { EditorState } from "prosemirror-state";
import Link from "@tiptap/extension-link";
import ListItem from "@tiptap/extension-list-item";
import NoteTippyOptions from "./getOptions";
import PageLoader from "components/Loaders/PageLoader";
import RouteChangeConformationModal from "components/RouteChangeModal/RouteChangeInfoModal";
import { SlashCommands } from "./suggestion/SlashCommands";
import StarterKit from "@tiptap/starter-kit";
import SvgIcon from "components/Icon/SvgIcon";
import TextAlign from "@tiptap/extension-text-align";
import TextStyle from "@tiptap/extension-text-style";
import Typography from "@tiptap/extension-typography";
import Underline from "@tiptap/extension-underline";
import TaskItem from "@tiptap/extension-task-item";
import TaskList from "@tiptap/extension-task-list";
import tippy, { Instance } from "tippy.js";
import { useDispatch } from "react-redux";
import usePermission from "hooks/usePermission";
import { PERMISSIONS } from "constants/Permissions.constant";
import store from "redux/store";

type commandsType = {
  id: number;
  icon: string;
  title: string;
  command: ({ editor, range }: CommandTypes) => void;
};

type CommandsListType = {
  items: commandsType[];
  selectItem: (index: number) => void;
  selectedIndex: number;
};

function CommandsList(props: CommandsListType) {
  const { items, selectItem, selectedIndex } = props;

  return (
    <ul className="commands-list-container">
      {items?.map(({ title, icon }: CommandListType, idx: number) => {
        return (
          <li
            key={idx}
            tabIndex={idx}
            onClick={() => selectItem(idx)}
            className={`commands-list-item ${
              selectedIndex === idx && "commands-list-item-active"
            }`}
          >
            <SvgIcon iconName={icon} className="" />
            {title}
          </li>
        );
      })}
    </ul>
  );
}

const extensions = [
  Color.configure({ types: [TextStyle.name, ListItem.name] }),
  TextStyle.configure({ types: [ListItem.name] } as any),
  StarterKit.configure({
    bulletList: {
      keepMarks: true,
      keepAttributes: false,
    },
    orderedList: {
      keepMarks: true,
      keepAttributes: false,
    },
  }),
  Typography.configure(),
  TextAlign.configure({
    types: ["heading", "paragraph"],
  }),
  SlashCommands.configure({
    commands: Commands,
    component: CommandsList,
  }),
  Underline.configure(),
  Link.configure(),
  TaskList.configure(),
  TaskItem.configure({
    nested: true,
  }),
];

type EditorPropsType = {
  // title?: string;
  content: string;
  isEdit: boolean;
  setIsEdit?: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading?: boolean;
  needMenu?: boolean;
  EditorContentClassName?: string;
  mainDivClassName?: string;
  EditorWrapperClassName?: string;
  onSave?: (editor: Editor | null) => void;
  saveBtnName?: string;
  onCancel?: () => void;
  cancelBtnName?: string;
  isLoadingSave?: boolean;
  isLoadingCancel?: boolean;
  needAlert?: boolean;
  publishRef?: React.RefObject<HTMLButtonElement>;
  contentId: number | string;
  hasUpdatePermission?: boolean;
  fileName?: (
    setIsDirty?: React.Dispatch<React.SetStateAction<boolean>>
  ) => JSX.Element;
  editDivClassName?: string;
  needCopyBtn?: boolean;
  EditorClassName?: string;
  description?: string;
  setDescription?: React.Dispatch<
    React.SetStateAction<string | null | undefined>
  >;
  setIsSaveDefault?: React.Dispatch<React.SetStateAction<boolean>> | null;
};

function resetEditorState(editor: Editor | null, content: string) {
  if (editor) {
    const newState = EditorState.create({
      doc: createDocument(content, editor.schema),
      schema: editor.schema,
      plugins: editor.state.plugins,
    });
    editor.view.updateState(newState);
  }
}

const Note = (props: EditorPropsType) => {
  const {
    // title = "",
    content,
    isEdit,
    setIsEdit,
    isLoading,
    needMenu,
    EditorContentClassName = "",
    mainDivClassName = "",
    EditorWrapperClassName = "",
    onSave,
    saveBtnName = "Save",
    onCancel,
    cancelBtnName = "Cancel",
    isLoadingSave = false,
    isLoadingCancel = false,
    needAlert = false,
    publishRef,
    contentId,
    hasUpdatePermission = false,
    fileName,
    editDivClassName = "",
    needCopyBtn = false,
    EditorClassName = "",
    description = "",
    setDescription,
    setIsSaveDefault,
  } = props;
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const linkBtnRef = useRef<HTMLButtonElement>(null);
  const tippyRef = useRef<Instance | null>(null);
  
  const dispatch = useDispatch();
  const { hasPermission } = usePermission();
  const playbookUpdatePermission = hasPermission(PERMISSIONS.PLAYBOOK.UPDATE);
  const playbookCreatePermission = hasPermission(PERMISSIONS.PLAYBOOK.CREATE);

  const [showSaveDefault, setShowSaveDefault] = useState<boolean>(false);

  const handleDocumentClick = useCallback((e: MouseEvent | any) => {
    const targetIsNotShowDefault = e?.target && e?.target?.id !== "showDefault";

    if (targetIsNotShowDefault) {
      setShowSaveDefault(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [handleDocumentClick]);

  const getCurrentNodeName = () => {
    const CurrentNode = getSelection()?.focusNode?.parentElement;
    return <>{CurrentNode && CurrentNode.tagName}</>;
  };

  const getCommonElement = (
    editor: Editor,
    isEditEnable: boolean,
    want_color = false,
    want_group = false,
    want_align = true
  ) => {
    const CurrentNode = getSelection()?.focusNode?.parentElement;
    return (
      <>
        <>{CurrentNode && CurrentNode.tagName}</>
        <button
          onClick={() => editor.chain().focus().toggleBold().run()}
          disabled={
            !editor.can().chain().focus().toggleBold().run() || !isEditEnable
          }
          className={
            editor.isActive("bold")
              ? "bg-black/[0.2] text-white rounded-md pr-1"
              : "pr-1 rounded-md hover:bg-gray-300"
          }
        >
          <SvgIcon iconName="BoldIcon" className="" />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleItalic().run()}
          disabled={
            !editor.can().chain().focus().toggleItalic().run() || !isEditEnable
          }
          className={
            editor.isActive("italic")
              ? "bg-black/[0.2] text-white rounded-md pr-1"
              : "pr-1 rounded-md hover:bg-gray-300"
          }
        >
          <SvgIcon iconName="ItalicIcon" className="" />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleUnderline().run()}
          disabled={
            !editor.can().chain().focus().toggleUnderline().run() ||
            !isEditEnable
          }
          className={
            editor.isActive("underline")
              ? "bg-black/[0.2] text-white rounded-md pr-1"
              : "pr-1 rounded-md hover:bg-gray-300"
          }
        >
          <SvgIcon iconName="UnderlineIcon" className="" />
        </button>
        {/* <button
          onClick={() => editor.chain().focus().toggleStrike().run()}
          disabled={
            !editor.can().chain().focus().toggleStrike().run() || !isEditEnable
          }
          className={
            editor.isActive("strike")
              ? "bg-black text-white rounded-md px-2"
              : "px-2 rounded-md hover:bg-gray-300"
          }
        >
          strike
        </button> */}
        {want_align && (
          <>
            {want_group ? (
              <NoteTippyOptions
                buttons={textAlignGroup(editor, isEdit)}
                isEditEnable={isEdit}
              >
                <SvgIcon iconName="LeftalignIcon" className="" />
              </NoteTippyOptions>
            ) : (
              <>
                <button
                  onClick={() =>
                    editor.chain().focus().setTextAlign("left").run()
                  }
                  className={
                    editor.isActive({ textAlign: "left" })
                      ? "bg-black/[0.2] text-white rounded-md px-2"
                      : "px-2 rounded-md hover:bg-gray-300"
                  }
                  disabled={!isEditEnable}
                >
                  <SvgIcon iconName="LeftalignIcon" className="" />
                </button>
                <button
                  onClick={() =>
                    editor.chain().focus().setTextAlign("center").run()
                  }
                  className={
                    editor.isActive({ textAlign: "center" })
                      ? "bg-black/[0.2] text-white rounded-md px-2"
                      : "px-2 rounded-md hover:bg-gray-300"
                  }
                  disabled={!isEditEnable}
                >
                  <SvgIcon iconName="CenteralignIcon" className="" />
                </button>
                <button
                  onClick={() =>
                    editor.chain().focus().setTextAlign("right").run()
                  }
                  className={
                    editor.isActive({ textAlign: "right" })
                      ? "bg-black/[0.2] text-white rounded-md px-2"
                      : "px-2 rounded-md hover:bg-gray-300"
                  }
                  disabled={!isEditEnable}
                >
                  <SvgIcon iconName="RightalignIcon" className="" />
                </button>
                <button
                  onClick={() =>
                    editor.chain().focus().setTextAlign("justify").run()
                  }
                  className={
                    editor.isActive({ textAlign: "justify" })
                      ? "bg-black/[0.2] text-white rounded-md px-2"
                      : "px-2 rounded-md hover:bg-gray-300"
                  }
                  disabled={!isEditEnable}
                >
                  <SvgIcon iconName="JustifyalignIcon" className="" />
                </button>
              </>
            )}
          </>
        )}

        {want_group ? (
          <NoteTippyOptions
            buttons={headingGroup(editor, isEdit)}
            isEditEnable={isEdit}
          >
            {/* Heading */}
            <SvgIcon iconName="H1Icon" className="" />
          </NoteTippyOptions>
        ) : (
          <>
            <button
              onClick={() =>
                editor.chain().focus().toggleHeading({ level: 1 }).run()
              }
              className={
                editor.isActive("heading", { level: 1 })
                  ? "bg-black/[0.2] text-white rounded-md pr-1 pl-2"
                  : "pr-1 pl-2 rounded-md hover:bg-gray-300"
              }
              disabled={!isEditEnable}
            >
              <SvgIcon iconName="H1Icon" className="" />
            </button>
            <button
              onClick={() =>
                editor.chain().focus().toggleHeading({ level: 2 }).run()
              }
              className={
                editor.isActive("heading", { level: 2 })
                  ? "bg-black/[0.2] text-white rounded-md px-2"
                  : "px-2 rounded-md hover:bg-gray-300"
              }
              disabled={!isEditEnable}
            >
              <SvgIcon iconName="H2Icon" className="" />
            </button>
            <button
              onClick={() =>
                editor.chain().focus().toggleHeading({ level: 3 }).run()
              }
              className={
                editor.isActive("heading", { level: 3 })
                  ? "bg-black/[0.2] text-white rounded-md px-2"
                  : "px-2 rounded-md hover:bg-gray-300"
              }
              disabled={!isEditEnable}
            >
              <SvgIcon iconName="H3Icon" className="" />
            </button>
            {/* <button
              // onClick={!editor.isActive("link") ? setLink : clearLink}
              className={
                editor.isActive("link")
                  ? "bg-black/[0.2] text-white rounded-md pr-3 pl-2"
                  : "pr-3 pl-2 rounded-md hover:bg-gray-300"
              }
              id="linkBtn"
              ref={linkBtnRef}
            >
              <Link2 />
            </button> */}
          </>
        )}
        {want_color && (
          <input
            type="color"
            onInput={(event) =>
              editor
                .chain()
                .focus()
                .setColor((event?.target as HTMLInputElement)?.value)
                .run()
            }
            value={
              editor?.getAttributes("textStyle")?.color
                ? editor?.getAttributes("textStyle")?.color
                : "#000000"
            }
            data-testid="setColor"
            name="ColorText"
            disabled={!isEditEnable}
          />
        )}
      </>
    );
  };

  const getUncommonElement = (
    editor: Editor,
    isEdit: boolean,
    is_bubble: boolean = false
  ) => {
    return (
      <>
        {is_bubble && (
          <button
            onClick={() =>
              editor.chain().focus().toggleHeading({ level: 4 }).run()
            }
            className={
              editor.isActive("heading", { level: 4 })
                ? "bg-black/[0.2] text-white rounded-md px-2"
                : "px-2 rounded-md hover:bg-gray-300"
            }
            disabled={!isEdit}
          >
            <SvgIcon iconName="H4Icon" className="" />
          </button>
        )}
        {/* <button
          onClick={() => editor.chain().focus().toggleCode().run()}
          disabled={!editor.can().chain().focus().toggleCode().run() || !isEdit}
          className={
            editor.isActive("code")
              ? "bg-black text-white rounded-md px-2"
              : "px-2 rounded-md hover:bg-gray-300"
          }
        >
          code
        </button> */}
        <button
          onClick={() => editor.chain().focus().setParagraph().run()}
          className={
            editor.isActive("paragraph")
              ? "bg-black/[0.2] text-white rounded-md px-2"
              : "px-2 rounded-md hover:bg-gray-300"
          }
          disabled={!isEdit}
        >
          <SvgIcon iconName="ParagraphIcon" className="" />
        </button>

        <button
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={
            editor.isActive("bulletList")
              ? "bg-black/[0.2] text-white rounded-md px-2"
              : "px-2 rounded-md hover:bg-gray-300"
          }
          disabled={!isEdit}
        >
          <SvgIcon iconName="BulletLineIcon" className="" />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          className={
            editor.isActive("orderedList")
              ? "bg-black/[0.2] text-white rounded-md px-2"
              : "px-2 rounded-md hover:bg-gray-300"
          }
          disabled={!isEdit}
        >
          <SvgIcon iconName="OrderListIcon" className="" />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleCodeBlock().run()}
          className={
            editor.isActive("codeBlock")
              ? "bg-black/[0.2] text-white rounded-md px-2"
              : "px-2 rounded-md hover:bg-gray-300"
          }
          disabled={!isEdit}
        >
          <SvgIcon iconName="CodeBlockIcon" className="" />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleBlockquote().run()}
          className={
            editor.isActive("blockquote")
              ? "bg-black/[0.2] text-white rounded-md px-2"
              : "px-2 rounded-md hover:bg-gray-300"
          }
          disabled={!isEdit}
        >
          <SvgIcon iconName="BlockquoteIcon" className="" />
        </button>
        <button
          onClick={() => editor.chain().focus().setHorizontalRule().run()}
          className="px-2 rounded-md hover:bg-gray-300"
          disabled={!isEdit}
        >
          <SvgIcon iconName="HorizontalRuleIcon" className="" />
        </button>
        {/* <button
          onClick={() => editor.chain().focus().setHardBreak().run()}
          className="px-2 rounded-md hover:bg-gray-300"
          disabled={!isEdit}
        >
          hard break
        </button> */}
        <button
          onClick={() => editor.chain().focus().undo().run()}
          disabled={!editor.can().chain().focus().undo().run() || !isEdit}
          className={
            !editor.can().chain().focus().undo().run()
              ? "opacity-50"
              : "px-2 rounded-md hover:bg-gray-300"
          }
        >
          <SvgIcon iconName="UndoIcon2" className="" />
        </button>
        <button
          onClick={() => editor.chain().focus().redo().run()}
          disabled={!editor.can().chain().focus().redo().run() || !isEdit}
          className={
            !editor.can().chain().focus().redo().run()
              ? "opacity-50"
              : "px-2 rounded-md hover:bg-gray-300"
          }
        >
          <SvgIcon iconName="UndoIcon2" className="scale-x-[-1]" />
        </button>
        {/* <button
          onClick={() => editor.chain().focus().unsetColor().run()}
          data-testid="unsetColor"
          className="px-2 rounded-md hover:bg-gray-300"
          disabled={!isEdit}
        >
          Clear color
        </button> */}
        {/* <button
          onClick={() => editor.chain().focus().unsetAllMarks().run()}
          className="px-2 rounded-md hover:bg-gray-300"
          disabled={!isEdit}
        >
          clear marks
        </button>
        <button
          onClick={() => editor.chain().focus().clearNodes().run()}
          className="px-2 rounded-md hover:bg-gray-300"
          disabled={!isEdit}
        >
          clear nodes
        </button> */}
      </>
    );
  };

  const editor = useEditor(
    {
      extensions: extensions,
      content: content,
      editable: isEdit,
    },
    [content, contentId, isEdit]
  );

  const clearLink = useCallback(() => {
    editor?.chain().focus().extendMarkRange("link").unsetLink().run();
  }, [editor]);

  const copyNote = () => {
    if (editor) {
      const clipboardItem = new ClipboardItem({
        "text/html": new Blob([editor?.getHTML()], { type: "text/html" }),
        "text/plain": new Blob([editor?.getText()], { type: "text/plain" }),
      });
      const toastId = new Date().getTime();
      navigator.clipboard.write([clipboardItem]).then(
        (_) => {
          dispatch(
            setToast({
              message: "Summary Copied",
              type: "success",
              id: toastId,
            })
          );
        },
        (error) => {
          dispatch(
            setToast({
              message: "Something went wrong!",
              type: "error",
              id: toastId,
            })
          );
        }
      );
      setTimeout(() => {
        dispatch(removeToast({ id: toastId }));
      }, 2000);
    }
  };

  useEffect(() => {
    if (editor && editor?.can().undo() !== isDirty) {
      setIsDirty(editor?.can().undo());
    }
  }, [editor?.can().undo()]);

  useEffect(() => {
    if (isDirty) {
      const beforeUnloadHandler = (event: BeforeUnloadEvent) => {
        const confirmationMessage =
          "The changes made in Notes, will not be saved.";
        (event || window.event).returnValue = confirmationMessage;
        return confirmationMessage;
      };

      window.addEventListener("beforeunload", beforeUnloadHandler);

      return () => {
        window.removeEventListener("beforeunload", beforeUnloadHandler);
      };
    }
  }, [isDirty]);

  useEffect(() => {
    const keydownHandler = function (event: KeyboardEvent) {
      if (event?.key === "Enter") {
        event.preventDefault();
        editor
          ?.chain()
          .focus()
          .extendMarkRange("link")
          .setLink({ href: (event?.target as HTMLInputElement)?.value })
          .run();
        (event.target as HTMLInputElement).value = "";
        tippyRef?.current?.hide();
      }
    };

    const clickHandler = () => {
      if (editor?.isActive("link")) {
        clearLink();
      } else {
        tippyRef?.current?.show();
      }
    };

    if (tippyRef?.current) {
      const input = tippyRef?.current.popper.querySelector(
        "#linkInput"
      ) as HTMLInputElement;
      input?.addEventListener("keydown", keydownHandler);
    }
    if (linkBtnRef?.current) {
      linkBtnRef?.current.addEventListener("click", clickHandler);
    }

    return () => {
      if (tippyRef?.current) {
        const input = tippyRef?.current.popper.querySelector(
          "#linkInput"
        ) as HTMLInputElement;
        input?.removeEventListener("keydown", keydownHandler);
      }
      if (linkBtnRef?.current) {
        linkBtnRef?.current.removeEventListener("click", clickHandler);
      }
    };
  }, [editor, tippyRef, linkBtnRef, clearLink]);

  const createTippy = useCallback(() => {
    if (editor && editor.options?.element && linkBtnRef.current) {
      if (tippyRef.current) {
        const updateTippyPosition = () => {
          if (tippyRef.current && editor) {
            tippyRef.current.setProps({
              getReferenceClientRect: () => {
                return posToDOMRect(
                  editor.view,
                  editor.state.selection.from,
                  editor.state.selection.to
                );
              },
            });
          }
        };
        updateTippyPosition();
        return;
      }

      tippyRef.current = tippy(linkBtnRef.current, {
        duration: 0,
        getReferenceClientRect: () =>
          posToDOMRect(
            editor.view,
            editor.state.selection.from,
            editor.state.selection.to
          ),
        content: `
          <div class="bg-white p-3 rounded-md shadow-md border-2">
            <input id="linkInput" type="text" class="border border-gray-300 focus:outline-none focus:ring-2 rounded-md px-2 py-1 w-full" placeholder="Enter link here" autocomplete="off">
          </div>
        `,
        allowHTML: true,
        interactive: true,
        trigger: "manual",
        placement: "bottom",
        hideOnClick: true,
      });
    }
  }, [editor?.state.selection.from, editor?.state.selection.to]);

  const onDescriptionChange = (
    e: React.ChangeEvent<HTMLTextAreaElement> | any
  ) => {
    setDescription && setDescription(e?.target?.value);
  };


  useEffect(() => {
    createTippy();
  }, [createTippy]);
  return (
    <div className={mainDivClassName}>
      {isDirty && needAlert && (
        <RouteChangeConformationModal
          isDirtyCondition={isDirty}
          promptMessage="The changes made in Notes, will not be saved."
        />
      )}
      {isLoading && (
        <div className="justify-center">
          <PageLoader />
        </div>
      )}
      {fileName ? (
        <div className="flex sm:flex-wrap sm:justify-start sm:gap-2 bg-[#6558f50f] rounded-lg items-center justify-between p-2 text-[#6558f5]">
          {onCancel && isEdit && (
            <div>
              <ArrowLeft
                className="cursor-pointer"
                onClick={() => {
                  resetEditorState(editor, content);
                  onCancel();
                }}
              ></ArrowLeft>
            </div>
          )}
          {fileName(setIsDirty)}
          <div className="flex gap-2 sm:flex-wrap">
            {editor &&
              (playbookUpdatePermission ||
                (!content && playbookCreatePermission)) && (
                <>
                  <button
                    onClick={() => editor.chain().focus().undo().run()}
                    disabled={
                      !editor.can().chain().focus().undo().run() || !isEdit
                    }
                    className={`${
                      !editor.can().chain().focus().undo().run()
                        ? "opacity-50"
                        : "px-2 rounded-md hover:bg-[#211698]"
                    } bg-[#6558F5] rounded px-2`}
                  >
                    <div className="flex">
                      <SvgIcon
                        iconName="UndoIcon2"
                        className=""
                        iconcolor="white"
                      />
                    </div>
                  </button>
                  <button
                    onClick={() => editor.chain().focus().redo().run()}
                    disabled={
                      !editor.can().chain().focus().redo().run() || !isEdit
                    }
                    className={`${
                      !editor.can().chain().focus().redo().run()
                        ? "opacity-50"
                        : "px-2 rounded-md hover:bg-[#211698]"
                    } bg-[#6558F5] rounded px-2`}
                  >
                    <div className="flex">
                      <SvgIcon
                        iconName="UndoIcon2"
                        className="scale-x-[-1]"
                        iconcolor="white"
                      />
                    </div>
                  </button>
                </>
              )}
            {onSave && isEdit && (
              <div className="flex gap-2 select-none">
                <div className="flex items-center gap-1">
                  <Button
                    className={`bg-[#6558F5] hover:bg-[#211698] text-white ${
                      !isDirty && "pointer-events-none opacity-50"
                    }`}
                    onClick={() => {  
                      onSave(editor);
                      setIsDirty(false);
                    }}
                    loading={isLoadingSave}
                    buttonRef={publishRef}
                  >
                    {saveBtnName}
                  </Button>
                  {setIsSaveDefault && playbookCreatePermission && (
                    <>
                      <p
                        className={`bg-[#6558F5] cursor-pointer hover:bg-[#211698] text-white p-[8px] rounded ${
                          !isDirty && "pointer-events-none opacity-50"
                        }`}
                        id="showDefault"
                        onClick={() => setShowSaveDefault(!showSaveDefault)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          className="h-5 w-5"
                          id="showDefault"
                        >
                          <path
                            fillRule="evenodd"
                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                      </p>
                      {showSaveDefault && (
                        <p
                          className="absolute text-white bg-[#6558F5] hover:bg-[#211698] py-1 cursor-pointer px-2 rounded select-none mt-20"
                          onClick={() => {
                            setIsSaveDefault(true);
                            setShowSaveDefault(false);
                          }}
                        >
                          Save as playbook
                        </p>
                      )}
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <>
          {onSave && isEdit && (
            <div className="flex gap-2 justify-end py-2">
              <div className="">
                <Button
                  className={`bg-[#6558F5] text-white ${
                    !isDirty && "pointer-events-none opacity-50"
                  }`}
                  onClick={() => {
                    onSave(editor);
                    setIsDirty(false);
                  }}
                  loading={isLoadingSave}
                  buttonRef={publishRef}
                >
                  {saveBtnName}
                </Button>
              </div>
              {onCancel && (
                <div className="">
                  <Button
                    className={`bg-[#6558F5] text-white `}
                    onClick={() => {
                      resetEditorState(editor, content);
                      onCancel();
                    }}
                    loading={isLoadingCancel}
                  >
                    {cancelBtnName}
                  </Button>
                </div>
              )}
            </div>
          )}
        </>
      )}
      {setDescription && (
        <div className="select-none flex gap-5 mx-2 my-4 items-center">
          <span>Description </span>
          {playbookUpdatePermission ||
          (!content && playbookCreatePermission) ? (
            <input
              type="text"
              name=""
              id=""
              className="p-2 border w-full rounded focus:focus-visible:fill-none"
              value={description}
              onChange={(e) => {
                if (e.target.value.length <= 250) {
                  setDescription(e?.target?.value);
                  setIsDirty(true);
                } else {
                  store.dispatch(removeToast({ id: 470000000 }));
                  store.dispatch(
                    setToast({
                      message: "Description message cannot exceed 250 characters",
                      type: "error",
                      id: 470000000,
                    })
                  );
                  setTimeout(() => {
                    store.dispatch(removeToast({ id: 470000000 }));
                  }, 2000);
                }
              }}
              // onChange={(e) => {
              //   setDescription(e?.target?.value);
              //   setIsDirty(true);
              // }}
            />
          ) : (
            <span>{}</span>
          )}
        </div>
      )}
      <div className={`${isLoading ? "hidden" : ""} ${EditorWrapperClassName}`}>
        {/* {editor && (
          <BubbleMenu
            editor={editor}
            tippyOptions={{ duration: 100 }}
            className="bg-white shadow-xl border-2 rounded-md p-1 flex flex-wrap items-center"
          >
            {getCommonElement(editor, isEdit, true, false, false)}
          </BubbleMenu>
        )} */}

        {editor && (
          <BubbleMenu
            editor={editor}
            tippyOptions={{ duration: 100 }}
            className="hover:select-none bg-white shadow-xl border-2 rounded-md mb-[-10px] px-2 flex flex-wrap items-center !transform:translate-[900px-153px]"
          >
            {getCurrentNodeName()}
          </BubbleMenu>
        )}
        {/* {needMenu && (
          <div
            className={`!w-full prose border rounded-md border-gray-400 p-2 ${
              !isEdit && "opacity-50"
            }`}
          >
            {editor && (
              <div className="flex flex-wrap gap-3">
                {getCommonElement(editor, isEdit, false, true)}
                {getUncommonElement(editor, isEdit)}
              </div>
            )}
          </div>
        )} */}
        <div
          className={`prose prose-a:text-primaryColor prose-a:cursor-pointer min-h-full ${EditorContentClassName} ${
            isEdit && "border-[2px] border-black rounded-md"
          }`}
        >
          <div className="relative bg-white content-notes">
            <EditorContent editor={editor} className={EditorClassName} />
            {needCopyBtn && !isEdit && (
              <div
                className={`flex w-full justify-end gap-2 p-2 ${editDivClassName}`}
                style={{ width: "-webkit-fill-available" }}
              >
                <p
                  className="cursor-pointer border-solid bg-white border-[2px] my-0 rounded-full px-3 py-1 text-[14px] border-[#5855d6] hover:text-white hover:bg-[#5855d6] transition-all"
                  onClick={copyNote}
                >
                  Copy Note
                </p>
                {hasUpdatePermission && !isEdit && setIsEdit && (
                  <Edit
                    className="cursor-pointer"
                    onClick={() => setIsEdit(true)}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Note;
