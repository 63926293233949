import InputField from "components/FormField/common/inputField";
import AsyncSelectField from "components/FormField/common/OptionFIeld";
import Button from "components/Theme/Button";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  useAddSuperAdminPlanPost,
  usePutSuperAdminSubscriptionPlans,
} from "../services";
import CheckBoxField from "components/FormField/common/CheckBoxField";
import { SuperAdminPlanSchema } from "modules/Setting/sub-modules/PaymentSetting/schema/superadminplan-validation";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  addPlanType,
  aiModelType,
  priceOptionType,
  priceType,
  specificationKey,
  specificationType,
  UpdatePlanType,
} from "../types";
import { capitalize, includes } from "lodash";

const AddUpdatePlan = (props: UpdatePlanType) => {
  const {
    planData,
    setUpdateAddBack,
    selectedPlanData,
    addPlan,
    setSuperPlanData,
  } = props;
  const {
    updateSubscriptionPaymentPlanApi,
    isLoading,
    isError: errorPut,
  } = usePutSuperAdminSubscriptionPlans();
  const [specifications, setSpecifications] = useState([
    ...selectedPlanData?.specification,
  ]);
  const {
    addSuperAdminPlanApi,
    isLoading: addPlanLoader,
    isError: errorPost,
  } = useAddSuperAdminPlanPost();
  const [isValidSubmit, setIsValidSubmit] = useState<boolean>(true);

  const [isCheckedBox, setIsCheckedBox] = useState<boolean>(false);

  const notesAI_2 = [
    { name_type: "NotesAI", limit: true, name: "anthropic", showtic: false },
    {
      name_type: "NotesAI",
      limit: true,
      name: "Claude 3 Opus",
      showtic: false,
    },
    {
      name_type: "NotesAI",
      limit: true,
      name: "Claude 3 Sonnet",
      showtic: false,
    },
    {
      name_type: "NotesAI",
      limit: true,
      name: "Claude 3 Haiku",
      showtic: false,
    },
    { name_type: "NotesAI", limit: true, name: "Claude 2.1", showtic: false },
    { name_type: "NotesAI", limit: true, name: "Claude 2", showtic: false },
    {
      name_type: "NotesAI",
      limit: true,
      name: "Claude Instant 1.2",
      showtic: false,
    },
    {
      name_type: "NotesAI",
      limit: true,
      name: "Claude 3.5 Sonnet",
      showtic: false,
    },
  ];

  const transcriptAI_2 = [
    { name_type: "TranscriptAI", limit: true, name: "whisper", showtic: false },
    {
      name_type: "TranscriptAI",
      limit: true,
      name: "assemblyai",
      showtic: false,
    },
    { name_type: "TranscriptAI", limit: true, name: "amazon", showtic: false },
  ];

  specifications?.forEach((model, i) => {
    if (model?.name_type === "NotesAI") {
      notesAI_2?.forEach((element) => {
        if (element?.name === model?.name) {
          element.showtic = true;
        }
      });
    } else if (model.name_type === "TranscriptAI") {
      transcriptAI_2?.forEach((element) => {
        if (element?.name === model?.name) {
          element.showtic = true;
        }
      });
    }
  });

  const handleHideShowInput = (isChecked: boolean, index: number) => {
    const updatedSpecifications = [...specifications];
    updatedSpecifications[index].hideshow = isChecked;
    setIsCheckedBox(isChecked);
    if (!isChecked) {
      updatedSpecifications[index].limit = "0";
      updatedSpecifications[index].name = updatedSpecifications[
        index
      ].name.replace(
        String(updatedSpecifications[index].name.match(/\d+/g)),
        "0"
      );
      setValue(`specification.${index}.limit`, "0");
    }

    setSpecifications(updatedSpecifications);
  };

  const onSpecificationLableChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    if (parseInt(e.target.value) <= 0) {
      return;
    }
    const updatedSpecifications = specifications?.map(
      (spec: specificationType, i: Number) => {
        if (index === i) {
          return {
            ...spec,
            name: spec?.name?.replace(
              String(spec.name.match(/\d+/g)),
              `${e.target.value ? e.target.value : 0}`
            ),
            limit: `${e.target.value ? e.target.value : 0}`,
          };
        } else {
          return { ...spec };
        }
      }
    );
    setSpecifications(updatedSpecifications);
  };

  const prices = planData?.prices?.map((price: priceType) => {
    return { label: price.price_amount, value: price.price_id };
  });

  const resetData = async () => {
    const selectedPlanLen = Object.keys(selectedPlanData).length;

    if (selectedPlanLen) {
      const { plan_name, description, specification } = selectedPlanData;
      reset({
        plan_name: capitalize(plan_name),
        description: description,
        specification: specification?.map((spec: specificationType) => {
          if (spec?.limit === "false") {
            spec = { ...spec, limit: false };
          } else if (spec?.limit === "true") {
            spec = { ...spec, limit: true };
          }
          return {
            name_type: spec.name_type,
            limit: spec.limit,
            name: spec.name,
          };
        }),
      });
    }
  };

  const defaultSelectValue = prices
    ?.filter(
      (price: priceOptionType) =>
        price?.value?.toString() === selectedPlanData?.price_id?.toString()
    )
    .map((price) => ({
      label: price?.label?.toString(),
      value: price?.value,
    }))[0];

  const {
    register,
    handleSubmit,
    setError,
    reset,
    getValues,
    setValue,
    clearErrors,
    control,
    formState: { errors },
  } = useForm<addPlanType>({
    mode: "onChange",
    defaultValues: {
      price_id: defaultSelectValue,
    },
    resolver: yupResolver(SuperAdminPlanSchema as any),
  });

  const onSpecificationValidation = (
    e: React.ChangeEvent<HTMLInputElement>,
    fieldName: specificationKey | `transcriptHidden`,
    unlimitedflag: boolean = false,
    name?: string,
    value?: any
  ) => {
    const isNotesAI = getValues().NotesAI.some((item:any) => Object.values(item).includes(true));
    const isTranscriptAI = getValues().TranscriptAI.some((item:any) => Object.values(item).includes(true));
    
    getValues().specification.forEach((spec, i) => {
      if((!spec.hideshow && spec.limit !== "-1") && spec.name){

      }
    })

    

    if ((name === "notes" && (value === "-1" || value === "true")) || (!isNotesAI && !isTranscriptAI)) {
      setError("transcriptHidden", {
        type: "custom",
        message:
          "You must need to select the atleast one transcript model and a notes model",
      });
      setIsValidSubmit(false);
      return
    }

    if(name){
      if (("notes" === name && (value === "0" || value === "false")) || (isNotesAI && isTranscriptAI)) {
        clearErrors("transcriptHidden");
        setIsValidSubmit(true);
        return;
      }
    }

    if (unlimitedflag) {
      setIsValidSubmit(true);
      return;
    }

    if (e.target.value === "") {
      setError(fieldName, {
        type: "custom",
        message: "The value cannot be empty",
      });
      setIsValidSubmit(false);
      return;
    }

    if (Number(e.target.value) < 0) {
      setError(fieldName, {
        type: "custom",
        message: "The value cannot be in negative",
      });
      setIsValidSubmit(false);
    } else if (Number(e.target.value) >= 300) {
      setError(fieldName, {
        type: "custom",
        message: "The value cannot be more than 300",
      });
      setIsValidSubmit(false);
    }
    if (Number(e.target.value) >= 0 && Number(e.target.value) <= 100) {
      clearErrors(fieldName);
      setIsValidSubmit(true);
    }
  };

  const onSubmit = handleSubmit(async (plandata: addPlanType) => {
    if (isValidSubmit) {
      const noteAI = plandata?.NotesAI?.map(
        (item: { [key: string]: boolean }) => {
          const name = Object.keys(item)[0];
          const add = item[name];
          return { name, add };
        }
      );

      const transcriptAI = plandata?.TranscriptAI?.map(
        (item: { [key: string]: boolean }) => {
          const name = Object.keys(item)[0];
          const add = item[name];
          return { name, add };
        }
      );

      plandata.NotesAI = noteAI;
      plandata.TranscriptAI = transcriptAI;
      plandata.specification = plandata.specification.map(
        (spec: specificationType) => {
          return {
            ...spec,
            name: `${
              spec.name.includes("-1")
                ? spec.name
                : spec.name.replaceAll("-", "")
            }`,
            limit:
              typeof spec.limit === "boolean"
                ? spec.limit
                  ? "true"
                  : "false"
                : spec.limit,
          };
        }
      );
      plandata.price = plandata?.price_id?.value ?? selectedPlanData?.price_id;
      if (addPlan) {
        plandata.specification = [
          ...plandata?.specification,
          { name_type: "user", limit: "-1", name: "Add user" },
        ];
        const data = await addSuperAdminPlanApi(plandata);
        if (!errorPut) {
          setSpecifications([]);
          setUpdateAddBack(false);
        }
      } else {
        const data = await updateSubscriptionPaymentPlanApi(
          selectedPlanData?.id,
          plandata
        );

        planData?.plan_data?.monthly?.map((plan: any, i: number) => {
          if (selectedPlanData.id === plan.id) {
            return (planData.plan_data.monthly[i] = data.data);
          } else {
            return plan;
          }
        });

        if (!errorPost) {
          setSpecifications([]);
          setUpdateAddBack(false);
        }
      }
    }
  });

  useEffect(() => {
    specifications?.forEach((spec: specificationType, i: number) => {
      if (spec.limit !== "-1" && spec.limit !== "0") {
        handleHideShowInput(true, i);
      }
    });
    resetData();
    if (parseInt(selectedPlanData?.price) === 0) {
      setValue("price_id", {
        label: "is_admin_plan",
        value: `${selectedPlanData?.plan_name?.toLowerCase()}`,
      });

      if (selectedPlanData.is_admin_show) {
        setValue("is_admin_show", true);
      } else {
        setValue("is_admin_show", false);
      }
    }
  }, []);

  const onChangeHandler = () => {
    const currentValues = getValues();
    currentValues?.specification?.forEach((specs, index) => {
      if (
        specs.name_type === "notes" &&
        (specifications[index].hideshow || specs.limit === "-1")
      ) {
        currentValues?.specification?.forEach((spec, i) => {
          if (
            (spec.name_type === "recording" ||
              spec.name_type === "transcript") &&
            (!parseInt(String(spec.limit)) || String(specs.hideshow) === "-1")
          ) {
            specifications[i].hideshow = true;
            specifications[i].limit = "1";
            setValue(`specification.${i}.limit`, "1");
            setValue(`specification.${i}.hideshow`, true);
            specifications[i].name = spec.name
              .replace(String(spec.name.match(/\d+/g)), "1")
              .replaceAll("-", "");
            console.log(spec, " SPEC if");
          }
        });
      } else if (
        specs.name_type === "notes" &&
        !specifications[index].hideshow &&
        specs.limit !== "-1"
      ) {
        currentValues?.specification?.forEach((spec, i) => {
          if (
            (spec.name_type === "recording" ||
              spec.name_type === "transcript") &&
            parseInt(String(spec.limit)) &&
            spec.limit !== "-1" &&
            specs.limit !== "-1" &&
            specs.limit !== "0"
          ) {
            specifications[i].hideshow = false;
            specifications[i].limit = "0";
            setValue(`specification.${i}.limit`, "0");
            setValue(`specification.${i}.hideshow`, false);
            specifications[i].name = spec.name
              .replace(String(spec.name.match(/\d+/g)), "0")
              .replaceAll("-", "");
            console.log(spec, " SPEC elif");
          }
        });
      }
      // all working below above is testing and for
      if (specs?.limit === "-1") {
        setValue(`specification.${index}.hideshow`, true);
        specifications[index].name = specs.name
          .replace(String(specs.name.match(/\d+/g)), "unlimited")
          .replaceAll("-", "");
      } else if (specs?.limit === "0" && specs.name !== "Add user") {
        if (specs.name.includes("unlimited")) {
          setValue(`specification.${index}.limit`, "1");
          specifications[index].limit = "1";
          specifications[index].name = specs.name
            .replace("unlimited", "1")
            .replaceAll("-", "");
        } else {
          if (!specifications[index].hideshow) {
            setValue(`specification.${index}.limit`, "0");
            specifications[index].limit = "0";
            specifications[index].name = specs.name
              .replace(String(specs.name.match(/\d+/g)), "0")
              .replaceAll("-", "");
          } else {
            setValue(`specification.${index}.limit`, "1");
            specifications[index].limit = "1";
            specifications[index].name = specs.name
              .replace(String(specs.name.match(/\d+/g)), "1")
              .replaceAll("-", "");
          }
        }
      }
    });
  };

  return (
    <div className="flex w-[70%] m-auto">
      <div>
        <div
          className={
            "border border-solid border-indigo-500 rounded-3xl p-8 lg:w-48 sm:w-full content-center"
          }
        >
          <div>
            <h2 className="text-[30px] mb-2 text-center text-indigo-500">
              {!addPlan ? <>Edit Plan</> : <>Add Plan</>}
            </h2>
          </div>
          <div
            className={
              "text-indigo-600 text-lg font-semibold leading-8 justify-between mt-5"
            }
          >
            <form onSubmit={onSubmit} onChange={onChangeHandler}>
              <div
                className={`grid ${
                  parseInt(selectedPlanData?.price) !== 0
                    ? "grid-cols-2"
                    : "grid-cols-1"
                } gap-4`}
              >
                {!selectedPlanData?.is_admin_show && !addPlan && (
                  <p className="text-center">
                    Note: This is hidden plan and can assigned to users only by
                    admin
                  </p>
                )}
                <div>
                  <InputField
                    id="plan_name"
                    name="plan_name"
                    label="Plan Name"
                    register={register}
                    required
                    type="text"
                    placeholder="Plan Name"
                    errors={errors?.plan_name}
                  />
                </div>
                {parseInt(selectedPlanData?.price) !== 0 && (
                  <div className="">
                    <AsyncSelectField
                      name="price_id"
                      label="Price"
                      control={control}
                      required
                      placeholder="Select Price"
                      defaultSelectValue={defaultSelectValue}
                      errors={errors?.price_id?.value}
                      getOptions={async () => {
                        const options = prices?.map(
                          (price: priceOptionType) => ({
                            label: price.label.toString(),
                            value: price.value,
                          })
                        );
                        return { option: options, count: options.length };
                      }}
                    />
                  </div>
                )}
              </div>
              <div>
                <InputField
                  id="description"
                  name="description"
                  label="Description"
                  register={register}
                  required
                  type="text"
                  placeholder="Description"
                  errors={errors?.description}
                />
              </div>
              {selectedPlanData?.is_admin_show || addPlan ? (
                <div className="flex flex-wrap align-middle">
                  <CheckBoxField
                    hasFieldLabel={false}
                    type="checkbox"
                    id={`is_admin_show`}
                    name={`is_admin_show`}
                    checked={false}
                    defaultChecked={true}
                    register={register}
                  />
                  <label
                    className="font-normal text-[15px] px-2 text-black"
                    htmlFor="is_admin_show"
                  >
                    Enable Plan
                  </label>
                </div>
              ) : (
                <></>
              )}
              <div className="my-3 font-normal">
                <hr />
                
                <hr />
                <div className="my-6">
                  <p className="my-4">Specification Limits</p>
                  {specifications?.map((spec: specificationType, i: number) => {
                    return (
                      <div
                        key={`specifications${spec?.id}${i}`}
                        className="grid-cols-4"
                      >
                        {spec?.limit === "false" || spec?.limit === "true" ? (
                          !["TranscriptAI", "NotesAI"].includes(
                            spec?.name_type
                          ) && (
                            <div className="col-span-2 flex mx-2 my-4">
                              <div className="mx-2">
                                <CheckBoxField
                                  wrapperClass="top-[3px]"
                                  hasFieldLabel={false}
                                  key={`specification.${i}`}
                                  type="checkbox"
                                  id={`specification.${i}`}
                                  name={`specification.${i}.limit`}
                                  register={register}
                                  errors={errors.specification}
                                />
                              </div>
                              <div>
                                <label
                                  className="text-black text-[14px] font-normal"
                                  htmlFor={`specification.${i}`}
                                >
                                  {spec.name}
                                </label>
                              </div>
                            </div>
                          )
                        ) : (
                          <div className="grid grid-cols-3">
                            {/* {addPlan && ( */}
                            {spec.name !== "Add user" && (
                              <div className="mx-4 flex">
                                <div>
                                  <CheckBoxField
                                    wrapperClass="top-[3px]"
                                    hasFieldLabel={false}
                                    key={`specification.${i}`}
                                    type="checkbox"
                                    id={`specification.${i}`}
                                    name={`specification.${i}.hideshow`}
                                    checked={false}
                                    defaultChecked={
                                      spec.limit !== "0" ? true : false
                                    }
                                    register={register}
                                    onChange={(e) => {
                                      onSpecificationValidation(
                                        e,
                                        `transcriptHidden`,
                                        true,
                                        spec.name_type,
                                        `${e.target.checked}`
                                      );
                                      return handleHideShowInput(
                                        e.target.checked,
                                        i
                                      );
                                    }}
                                    disabled={
                                      getValues()?.specification?.[i]?.limit ===
                                      "-1"
                                    }
                                  />
                                </div>
                                <div className="mx-2">
                                  <label
                                    className="text-black text-[14px] font-normal"
                                    htmlFor={`specification.${i}`}
                                  >
                                    {addPlan
                                      ? spec.name.replace(
                                          String(spec.name.match(/\d+/g)),
                                          "limit"
                                        )
                                      : spec.name
                                          .replace("-1", "unlimited")
                                          .replace(
                                            `${
                                              !spec.name.includes("-1") && "-"
                                            }`,
                                            ""
                                          )}
                                  </label>
                                </div>
                              </div>
                            )}

                            {/* )} */}
                            {spec.name !== "Add user" && (
                              <div className="flex align-middle">
                                <div className="min-h-[3.8rem]">
                                  <CheckBoxField
                                    wrapperClass="top-[3px]"
                                    hasFieldLabel={false}
                                    key={`specification.${i}checkbox`}
                                    type="checkbox"
                                    id={`specification.${i}infinite`}
                                    // name={`specification.${i}.limit`}
                                    defaultChecked={
                                      spec.limit === "-1" ? true : false
                                    }
                                    checked={false}
                                    register={register}
                                    onChange={(e) => {
                                      handleHideShowInput(!e.target.checked, i);
                                      setValue(
                                        `specification.${i}.limit`,
                                        `${e.target.checked ? "-1" : "1"}`
                                      );
                                      onSpecificationValidation(
                                        e,
                                        `transcriptHidden`,
                                        true,
                                        spec.name_type,
                                        `${
                                          getValues()?.specification?.[i]?.limit
                                        }`
                                      );
                                    }}
                                  />
                                </div>
                                <div>
                                  <label
                                    className="text-black text-[14px] font-normal mx-2"
                                    htmlFor={`specification.${i}infinite`}
                                  >
                                    Unlimited
                                  </label>
                                </div>
                              </div>
                            )}
                            {spec?.hideshow && spec.name !== "Add user" && (
                              <div className="">
                                <InputField
                                  key={spec.id}
                                  id={`specification.${i}`}
                                  name={`specification.${i}.limit`}
                                  className="m-0"
                                  register={register}
                                  required
                                  type="number"
                                  placeholder={`Specification ${i + 1}`}
                                  errors={errors.specification?.[i]}
                                  defaultValue={String(spec.limit)}
                                  onChange={(e) => {
                                    onSpecificationLableChange(e, i);
                                    return onSpecificationValidation(
                                      e,
                                      `specification.${i}`
                                    );
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
                <div className="my-6">
                  <p>Notes Model</p>  
                  <div className="grid grid-cols-4">
                    {notesAI_2?.map((spec: aiModelType, i: number) => {
                      return (
                        <div
                          className="flex mx-2 my-4"
                          key={`notesAI${spec.name}${i}`}
                        >
                          <div className="mx-2">
                            <CheckBoxField
                              wrapperClass="top-[3px]"
                              hasFieldLabel={false}
                              key={`NotesAI.${i}.${spec.name}`}
                              type="checkbox"
                              id={`NotesAI.${i}.${spec.name}`}
                              name={`NotesAI.${i}.${spec.name.replace(
                                ".",
                                "_"     
                              )}`}
                              onChange={(e) => {
                                onSpecificationValidation(
                                  e,
                                  `transcriptHidden`,
                                  true,
                                  spec.name_type,
                                  `${e.target.checked}`
                                );
                              }}
                              register={register}
                              errors={errors.specification}
                              defaultChecked={spec.showtic}
                            />
                          </div>
                          <div>
                            <label
                              className="text-black text-[14px] font-normal"
                              htmlFor={`NotesAI.${i}.${spec.name}`}
                            >
                              {capitalize(spec.name)}
                            </label>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <InputField
                    name={`transcriptHidden`}
                    hidden={true}
                    errors={errors.transcriptHidden}
                  />
                </div>
                <hr />
                <div className="my-6">
                  <p>Transcript Model</p>
                  <div className="grid grid-cols-4">
                    {transcriptAI_2?.map((spec: aiModelType, i: number) => {
                      return (
                        <div
                          className="flex mx-2 my-4"
                          key={`transcriptAI${spec.name}${i}`}
                        >
                          <div className="mx-2">
                            <CheckBoxField
                              wrapperClass="top-[3px]"
                              hasFieldLabel={false}
                              key={`TranscriptAI.${i}.${spec.name}`}
                              type="checkbox"
                              id={`TranscriptAI.${i}.${spec.name}`}
                              name={`TranscriptAI.${i}.${spec.name}`}
                              register={register}
                              onChange={(e) => {
                                onSpecificationValidation(
                                  e,
                                  `transcriptHidden`,
                                  true,
                                  spec.name_type,
                                  `${e.target.checked}`
                                );
                              }}
                              errors={errors.specification}
                              defaultChecked={spec.showtic}
                            />
                          </div>
                          <div>
                            <label
                              className="text-black text-[14px] font-normal"
                              htmlFor={`TranscriptAI.${i}.${spec.name}`}
                            >
                              {capitalize(spec.name)}
                            </label>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <InputField
                    name={`transcriptHidden`}
                    hidden={true}
                    errors={errors.transcriptHidden}
                  />
                </div>
              </div>
              <div>
                <div className="flex [&>*]:m-2 w-96">
                  <Button
                    loading={addPlanLoader ? addPlanLoader : isLoading}
                    className={`${
                      !isValidSubmit && "pointer-events-none"
                    } bg-indigo-500 text-white shadow-sm mt-6 rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 cursor-pointer w-full`}
                    type="submit"
                  >
                    Save
                  </Button>
                  <Button
                    className={
                      "bg-red-500 text-white shadow-sm mt-6 rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 cursor-pointer w-full"
                    }
                    onClick={() => {
                      specifications?.forEach(
                        (spec: specificationType, i: number) => {
                          if (specifications[i]?.hideshow) {
                            specifications[i].hideshow = false;
                          }
                        }
                      );
                      setUpdateAddBack(false);
                      setSpecifications([]);
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(AddUpdatePlan);
