import { PRIVATE_NAVIGATION } from "./navigation.constant";

export const BREAD_CRUMB = Object.freeze({
  Dashboard: {
    trails: [{ title: "Dashboard", path: PRIVATE_NAVIGATION.dashboard.view }],
    title: "Dashboard",
  },
  AdminDashboard: {
    trails: [],
    title: "Dashboard",
  },
  Customer: {
    trails: [],
    title: "Customer",
  },
  CustomerDetail: {
    trails: [
      {
        title: "Customer",
        path: PRIVATE_NAVIGATION.customer.view,
      },
    ],
    title: "Customer Detail",
  },
  // Setting: {
  //   trails: [{ title: "Dashboard", path: PRIVATE_NAVIGATION.dashboard.view }],
  //   title: "Setting",
  // },
  Appointment: {
    trails: [{ title: "Dashboard", path: PRIVATE_NAVIGATION.dashboard.view }],
    title: "Appointment",
  },
  AllPatient: {
    trails: [{ title: "Dashboard", path: PRIVATE_NAVIGATION.dashboard.view }],
    title: "Patient",
  },
  PersonalInformation: {
    trails: [
      {
        title: "Dashboard",
        path: PRIVATE_NAVIGATION.dashboard.view,
      },
      // { title: "Settings", path: PRIVATE_NAVIGATION.setting.view },
    ],
    title: "Personal Information",
  },
  RoleAndPermission: {
    trails: [
      {
        title: "Dashboard",
        path: PRIVATE_NAVIGATION.dashboard.view,
      },
      // { title: "Settings", path: PRIVATE_NAVIGATION.setting.view },
    ],
    title: "Role And Permission",
  },
  PromptsSetting: {
    trails: [
      {
        title: "Dashboard",
        path: PRIVATE_NAVIGATION.dashboard.view,
      },
      // { title: "Settings", path: PRIVATE_NAVIGATION.setting.view },
    ],
    title: "Prompts",
  },
  PlaybooksSetting: {
    trails: [
      {
        title: "Dashboard",
        path: PRIVATE_NAVIGATION.dashboard.view,
      },
      // { title: "Settings", path: PRIVATE_NAVIGATION.setting.view },
    ],
    title: "Playbooks",
  },
  UserManagement: {
    trails: [
      {
        title: "Dashboard",
        path: PRIVATE_NAVIGATION.dashboard.view,
      },

      // { title: "Settings", path: PRIVATE_NAVIGATION.setting.view },
    ],
    title: "User Management",
  },

  addUser: {
    trails: [
      {
        title: "Dashboard",
        path: PRIVATE_NAVIGATION.dashboard.view,
      },

      {
        title: "User Management",
        path: PRIVATE_NAVIGATION.setting.userManagement.view,
      },
    ],
    title: "User Add",
  },

  viewUser: {
    trails: [
      {
        title: "Dashboard",
        path: PRIVATE_NAVIGATION.dashboard.view,
      },

      {
        title: "User Management",
        path: PRIVATE_NAVIGATION.setting.userManagement.view,
      },
    ],
    title: "User Detail",
  },
  editUser: {
    trails: [
      {
        title: "Dashboard",
        path: PRIVATE_NAVIGATION.dashboard.view,
      },

      {
        title: "User",
        path: PRIVATE_NAVIGATION.setting.userManagement.view,
      },
    ],
    title: "Edit User",
  },
  Notes: {
    patient: {
      trails: [
        {
          title: "Dashboard",
          path: PRIVATE_NAVIGATION.dashboard.view,
        },
      ],
      title: "Patient Notes",
    },
    content: {
      trails: [
        {
          title: "Dashboard",
          path: PRIVATE_NAVIGATION.dashboard.view,
        },
      ],
      title: "Content Notes",
    },
    voice: {
      trails: [
        {
          title: "Dashboard",
          path: PRIVATE_NAVIGATION.dashboard.view,
        },
      ],
      title: "Voice Notes",
    },
    meeting: {
      trails: [
        {
          title: "Dashboard",
          path: PRIVATE_NAVIGATION.dashboard.view,
        },
      ],
      title: "Meeting Notes",
    },
  },
  MeetingFolder: {
    trails: [{ title: "Dashboard", path: PRIVATE_NAVIGATION.dashboard.view }],
    title: "Folders",
  },
  SubscriptionPlans: {
    trails: [
      {
        title: "Dashboard",
        path: PRIVATE_NAVIGATION.dashboard.view,
      },
      // { title: "Settings", path: PRIVATE_NAVIGATION.setting.subscription.view }, // on uncomment the breadcrumb looks like Dashboard > setting > Subscription
    ],
    title: "Subscription",
  },
  BillingDetails: {
    trails: [
      {
        title: "Dashboard",
        path: PRIVATE_NAVIGATION.dashboard.view,
      },
      // { title: "Settings", path: PRIVATE_NAVIGATION.setting.subscription.view }, // on uncomment the breadcrumb looks like Dashboard > setting > Subscription
    ],
    title: "Billing",
  },
  SuperAdminInvoices: {
    trails: [
      {
        title: "Dashboard",
        path: PRIVATE_NAVIGATION.dashboard.view,
      },
      // { title: "Settings", path: PRIVATE_NAVIGATION.setting.subscription.view }, // on uncomment the breadcrumb looks like Dashboard > setting > Subscription
    ],
    title: "Invoices",
  },
  PlanManagement: {
    trails: [
      {
        title: "Dashboard",
        path: PRIVATE_NAVIGATION.dashboard.view,
      },
      // { title: "Settings", path: PRIVATE_NAVIGATION.setting.subscription.view }, // on uncomment the breadcrumb looks like Dashboard > setting > Subscription
    ],
    title: "Plan Management",
  },
});
