import { useEffect, useState } from "react";
import CurrentPlan from "./CurrentPlan";
import InvoiceList from "./InvoiceList";
import Breadcrumbs from "components/Breadcrumbs";
import { BREAD_CRUMB } from "constants/breadcrumbs.constant";
import ShowPaymentOptions from "./ShowPaymentOptions";

const BillingDetails = () => {
  const [windowSize, setWindowSize] = useState<number>(window.innerWidth);

  const resizeTheDivs = () => {
    setWindowSize(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", resizeTheDivs);

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener("resize", resizeTheDivs);
    };
  }, [windowSize]);

  return (
    <div>
      <Breadcrumbs path={BREAD_CRUMB.BillingDetails} />
      <div
        className={
          windowSize <= 1080
            ? "flex m-auto w-full flex-wrap gap-8"
            : "flex m-auto w-full gap-8"
        }
      >
        <CurrentPlan />
        <ShowPaymentOptions />
      </div>
      <hr />
      <InvoiceList />
    </div>
  );
};

export default BillingDetails;
