import { useEffect, useState } from "react";
import { useGetUserSuggestionAPI, useShareTranscriptViaEmailAPI } from "../services"

import Button from "components/Theme/Button";
import ImageShow from "components/Image";
import PageLoader from "components/Loaders/PageLoader";
import { Plus } from "react-feather"
import { firstCharUpperCase } from "utils";
import AlertModal from "components/modal/AlertModal";
import AddNewUserModal from "modules/Setting/sub-modules/UserManagement/components/AddNewUser";

type userDetailType = {
    email: string;
    update_allowed: boolean;
    transcript_id: number | string;
}
type peopleDetailType = {
    email: string;
    first_name: string;
    last_name: string;
    role: string;
    imageUrl: string;
}
type InviteUserPropsType = {
    transcript_id: number | string;
    closeShowInviteUser: () => void;
}
export default function InviteUser(props: InviteUserPropsType) {
    const { transcript_id, closeShowInviteUser } = props;
    const [viewAlert, setViewAlert] = useState<boolean>(false)
    const [viewAddUser, setViewAddUser] = useState<{ open: boolean; id: number | null | string }>({ open: false, id: null })
    const [userDetail, setUserDetail] = useState<userDetailType>({
        email: '',
        update_allowed: false,
        transcript_id: transcript_id,
    })
    const [error, setError] = useState("")
    const [people, setPeople] = useState<peopleDetailType[]>([])
    const { isLoading, shareTranscriptViaEmailAPI } = useShareTranscriptViaEmailAPI();
    const { isLoading: isLoadingSuggestion, getUserSuggestionAPI } = useGetUserSuggestionAPI();

    useEffect(() => {
        const getSuggestionData = async () => {
            const { data, error } = await getUserSuggestionAPI()
            if (!error && data) {
                setPeople(data)
            }
        }
        getSuggestionData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    function validateEmail(email: string) {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }
    const onSubmit = async () => {
        const isValidEmail = validateEmail(userDetail.email)
        if (!isValidEmail) {
            setError("Please enter an valid email")
        }
        else {
            const { error, data } = await shareTranscriptViaEmailAPI(userDetail);
            if (!error && data) {
                if (data?.is_registered) {
                    setUserDetail({
                        email: '',
                        update_allowed: false,
                        transcript_id: transcript_id,
                    })
                    closeShowInviteUser()
                }
                else {
                    setViewAlert(true)
                }
            }
        }
    }

    return (
        <div className="block">
            <AlertModal
                visible={viewAlert}
                onClose={() => { setViewAlert(false) }}
                onCancel={() => { setViewAlert(false) }}
                showCrossIcon
                onSubmit={() => { setViewAddUser({ id: null, open: true }); setViewAlert(false) }}
                submitButtonText="Yes"
                cancelButtonText="No" 
            >
                <p className="text-md mb-3 flex ">
                    <span>Do you want to add a new user?</span>
                </p>
            </AlertModal>
            {viewAddUser.open ?
                <AddNewUserModal setViewMode={setViewAddUser} email={userDetail.email} /> :
                <>
                    <div>
                        <div className="text-center">
                            <svg
                                className="mx-auto h-12 w-12 text-gray-400"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 48 48"
                                aria-hidden="true"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M34 40h10v-4a6 6 0 00-10.712-3.714M34 40H14m20 0v-4a9.971 9.971 0 00-.712-3.714M14 40H4v-4a6 6 0 0110.713-3.714M14 40v-4c0-1.313.253-2.566.713-3.714m0 0A10.003 10.003 0 0124 26c4.21 0 7.813 2.602 9.288 6.286M30 14a6 6 0 11-12 0 6 6 0 0112 0zm12 6a4 4 0 11-8 0 4 4 0 018 0zm-28 0a4 4 0 11-8 0 4 4 0 018 0z"
                                />
                            </svg>
                            <h2 className="mt-2 text-base font-semibold leading-6 text-gray-900">Add the member</h2>
                            <p className="mt-1 text-sm text-gray-500">Enter an email below to invite the user to access the recording.</p>
                        </div>
                        <div className="mt-6 md:block flex flex-wrap items-center justify-center ">
                            <label htmlFor="emails" className="sr-only">
                                Email addresses
                            </label>
                            <div className="grid grid-cols-1 sm:flex-auto peer">
                                <input
                                    type="text"
                                    name="emails"
                                    id="emails"
                                    className="peer relative col-start-1 pl-2 row-start-1 border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 outline-0"
                                    placeholder="Enter an email"
                                    value={userDetail?.email}
                                    onChange={e => { setUserDetail({ ...userDetail, email: e?.target?.value }); setError("") }}

                                />
                                {error && <span className="text-red-600 text-sm m-auto">{error}</span>}
                                <div
                                    className="col-start-1 col-end-3 row-start-1 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 peer-focus:ring-2 peer-focus:ring-indigo-600"
                                    aria-hidden="true"
                                />
                                <div className="col-start-2 row-start-1 flex items-center">
                                    <span className="h-4 w-px flex-none bg-gray-200" aria-hidden="true" />
                                    <label htmlFor="role" className="sr-only">
                                        Role
                                    </label>
                                    <select
                                        id="role"
                                        name="role"
                                        className="select-custom-arrow rounded-md border-0 bg-transparent py-1.5 pl-4 pr-10 text-gray-900 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 outline-0"
                                        value={userDetail?.update_allowed ? "Yes" : "No"}
                                        onChange={e => {
                                            setUserDetail({ ...userDetail, update_allowed: e?.target?.value === "Yes" ? true : false })
                                        }}
                                    >
                                        <option value={"Yes"}>Can edit</option>
                                        <option value={"No"}>Can view</option>
                                    </select>
                                </div>
                            </div>
                            <div className={`md:ml-0 md:mt-4 mt-0 ml-4 content-start peer ${error && "mb-4"}`}>
                                <Button
                                    className="w-full rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    onClick={onSubmit}
                                    loading={isLoading}
                                >
                                    Send
                                </Button>
                            </div>
                        </div>
                    </div>
                    {isLoadingSuggestion ? <PageLoader /> :
                        people?.length > 0 &&
                        <div className="h-auto mt-5">
                            <h3 className="text-sm font-medium text-gray-500">Recommended members</h3>
                            <ul className="grid grid-cols-2 gap-4 mt-2 max-h-fit pb-2">
                                {people?.map((person, personIdx) => (
                                    <li key={`personId ${personIdx}`}>
                                        <button
                                            type="button"
                                            className="group flex w-full items-center justify-between space-x-3 rounded-full border border-gray-300 p-1 text-left shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                            onClick={() => {
                                                setUserDetail({ ...userDetail, email: person?.email })
                                            }}
                                        >
                                            <span className="flex min-w-0 flex-1 items-center space-x-3">
                                                <span className="block flex-shrink-0">
                                                    <ImageShow imgPath={person?.imageUrl} first_name={person?.first_name} last_name={person?.last_name} avatarWrapperClassName="w-[50px] h-[50px] md:w-[50px] md:h-[50px]" avatarInnerWrapperClassName="!rounded-full overflow-hidden" />
                                                </span>
                                                <span className="block min-w-0 flex-1">
                                                    <span className="block truncate text-sm font-medium text-gray-900">{firstCharUpperCase(person?.first_name + " " + person?.last_name)}</span>
                                                    <span className="block truncate text-[11px] font-medium text-gray-500">{firstCharUpperCase(person?.role)}</span>
                                                </span>
                                            </span>
                                            <span className="inline-flex h-10 w-10 flex-shrink-0 items-center justify-center">
                                                <Plus />
                                            </span>
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    }
                </>}
        </div>
    )
}
